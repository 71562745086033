import React, { useContext, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { IncomeTableContext } from '../../../IncomeTableContext';
import { TableContext, TableProvider } from '../../Table/TableContext';
import { getShouldDisplayDiff } from '../../../utils/getDiff';
import EditorHeader from './components/EditorHeader/EditorHeader';
import ColumnsOverview from './components/ColumnsOverview/ColumnsOverview';
import EditingArea from './components/EditingArea/EditingArea';
import RetailEditor from '../RetailEditor';
import './styles.scss';
import dayjs from 'dayjs';

const ColumnEditor = ({ onClose }) => {
  const { t } = useTranslation();
  const [activeEditor, setActiveEditor] = useState('retail');
  const [searchQuery, setSearchQuery] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const { 
    selectedColumn,
    setSelectedColumn,
    changeColumnType,
    toggleColumnTax,
    removeColumn,
    toggleColumnVisibility,
    toggleStoreVisible,
    toggleMallVisible,
    addColumn,
    deleteTable,
    changeColumnDates,
    activeEditorTab,
    setActiveEditorTab,
    tablesSettings,
    updateFilters,
    selectedMallId,
    selectTable,
    toggleEditing
  } = useContext(IncomeTableContext);

  const { tableUID, shownColumns, malls } = useContext(TableContext);

  // Get the raw columns from tablesSettings
  const allColumns = tablesSettings[tableUID]?.columns || [];
  
  // Filter columns based on whether diff columns should be shown
  const shouldDisplayDiff = getShouldDisplayDiff(allColumns);
  const columnsToShow = allColumns.filter(col => !col.isDiff || shouldDisplayDiff);

  // Handle programmatic navigation
  useEffect(() => {
    // If a column is selected, we should be in columns view
    if (selectedColumn) {
      setActiveEditor('columns');
    }
  }, [selectedColumn]);

  const handleClose = (e) => {
    if (!e || e.target.classList.contains('column-editor__overlay')) {
      setSelectedColumn(null);
      selectTable(null);
      toggleEditing();
      onClose();
    }
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirm(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirm(false);
  };

  const handleConfirmDelete = async () => {
    const success = await deleteTable(tableUID);
    if (success) {
      handleClose();
    }
  };

  const handleDateChange = (dates) => {
    if (selectedColumn) {
      changeColumnDates(tableUID, selectedColumn, dates);
    }
  };

  const handleTypeChange = (type) => {
    if (selectedColumn) {
      changeColumnType(tableUID, selectedColumn, type);
    }
  };

  const handleTaxChange = (tax) => {
    if (selectedColumn) {
      toggleColumnTax(tableUID, selectedColumn, tax);
    }
  };

  const handleAlignVat = () => {
    const nonDiffColumns = shownColumns.filter(col => !col.isDiff);
    if (nonDiffColumns.length > 0) {
      const firstTax = nonDiffColumns[0].tax;
      nonDiffColumns.forEach(col => {
        if (col.tax !== firstTax) {
          toggleColumnTax(tableUID, col.columnUID, firstTax);
        }
      });
    }
  };

  const handleAddColumn = async () => {
    const today = dayjs();
    const existingColumns = shownColumns.filter(col => !col.isDiff);
    
    // Set different date ranges based on existing columns
    let dateRange;
    if (existingColumns.length > 0) {
      const mostRecentColumn = existingColumns[0]; // Already sorted
      
      dateRange = dayjs(mostRecentColumn.from).isSame(today, 'month')
        ? {
            from: today.clone().subtract(1, 'month').startOf('month'),
            to: today.clone().subtract(1, 'month').endOf('month')
          }
        : {
            from: today.clone().startOf('month'),
            to: today.clone().subtract(1, 'day')
          };
    } else {
      dateRange = {
        from: today.clone().startOf('month'),
        to: today.clone().subtract(1, 'day')
      };
    }

    await addColumn(tableUID, dateRange);
  };

  const handleRemoveColumn = async (column) => {
    await removeColumn(tableUID, column.columnUID);
  };

  const handleToggleVisibility = (column) => {
    toggleColumnVisibility(tableUID, column.columnUID);
  };

  const handleEditorChange = (editor) => {
    setActiveEditor(editor);
    if (editor === 'columns') {
      setActiveEditorTab('picker');
      const firstColumn = shownColumns.find(col => !col.isDiff);
      if (firstColumn) {
        setSelectedColumn(firstColumn.columnUID);
      }
    }
  };

  const selectedColumnData = selectedColumn 
    ? shownColumns.find(col => col.columnUID === selectedColumn)
    : null;

  return createPortal(
    <div 
      className="column-editor__overlay"
      onClick={handleClose}
    >
      <div 
        className="column-editor__wrapper"
        onClick={e => e.stopPropagation()}
      >
        <EditorHeader
          activeTab={activeEditor === 'columns' ? activeEditorTab : null}
          onTabChange={activeEditor === 'columns' ? setActiveEditorTab : null}
          onDeleteTable={handleDeleteClick}
          activeEditor={activeEditor}
          onEditorChange={handleEditorChange}
          searchQuery={searchQuery}
          onSearchChange={setSearchQuery}
          tableUID={tableUID}
          tableSettings={tablesSettings[tableUID]}
          updateFilters={updateFilters}
          selectedMallId={selectedMallId}
          selectedMallData={malls.find(mall => mall.id === selectedMallId)}
          allStores={malls.flatMap(mall => mall.stores || [])}
        />

        {showDeleteConfirm && (
          <div className="delete-confirm-overlay">
            <div className="delete-confirm-dialog">
              <span>{t('Delete this table?')}</span>
              <div className="delete-confirm-actions">
                <button
                  type="button"
                  className="confirm-button"
                  onClick={handleConfirmDelete}
                >
                  {t('Yes')}
                </button>
                <button
                  type="button"
                  className="cancel-button"
                  onClick={handleCancelDelete}
                >
                  {t('No')}
                </button>
              </div>
            </div>
          </div>
        )}

        {activeEditor === 'columns' ? (
          <>
            <ColumnsOverview
              columns={columnsToShow}
              selectedColumnId={selectedColumn}
              onDateClick={(column) => {
                setSelectedColumn(column.columnUID);
                setActiveEditorTab('picker');
              }}
              onTypeClick={(column) => {
                setSelectedColumn(column.columnUID);
                setActiveEditorTab('types');
              }}
              onTaxClick={(column) => {
                setSelectedColumn(column.columnUID);
                setActiveEditorTab('tax');
              }}
              onRemoveColumn={handleRemoveColumn}
              onToggleVisibility={handleToggleVisibility}
              onAddColumn={handleAddColumn}
              shouldShowAdd={columnsToShow.filter(col => !col.isDiff).length < 6}
              activeTab={activeEditorTab}
              onAlignVat={handleAlignVat}
            />

            {selectedColumnData && (
              <EditingArea
                activeTab={activeEditorTab}
                selectedColumn={selectedColumnData}
                onDateChange={handleDateChange}
                onTypeChange={handleTypeChange}
                onTaxChange={handleTaxChange}
                onTabChange={setActiveEditorTab}
              />
            )}
          </>
        ) : (
          <TableProvider tableUID={tableUID}>
            <RetailEditor
              tableUID={tableUID}
              tableSettings={tablesSettings[tableUID]}
              toggleMallVisible={toggleMallVisible}
              toggleStoreVisible={toggleStoreVisible}
              activeSection={activeEditor}
              searchQuery={searchQuery}
              onClose={onClose}
            />
          </TableProvider>
        )}
      </div>
    </div>
  , document.body);
};

export default ColumnEditor; 