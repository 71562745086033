import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

function ExportProgress({ progress }) {
  const { t } = useTranslation();
  if (progress === 0) return null;
  
  return (
    <div className='ExportProgress'>
      <div className='ExportProgress__text'>{t('export')}</div>
      <div id='progressLine'>
        <div id='doneProgress' style={{ width: `${progress}%` }}></div>
      </div>
    </div>
  );
}

export default ExportProgress;
