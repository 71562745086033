import React from 'react';
import { useTranslation } from 'react-i18next';
import { getStoreIcon } from '../../../../../utils/storeIcons';
import './styles.scss';

const STORE_CATEGORIES = [
  'Food and Drinks',
  'Fashion & Travel',
  'House goods',
  'Special Stores',
  'Services',
  'Serving',
  'Other Retail',
  'Other Business'
];

const CategoryFilter = ({ tableUID, tableSettings, updateFilters, stores }) => {
  const { t } = useTranslation();

  const handleCategoryClick = (category) => {
    const newFilters = {
      ...tableSettings.filters,
      category: category === 'all' ? null : category
    };
    updateFilters(tableUID, newFilters);
  };

  const isActive = (category) => {
    const currentFilter = tableSettings.filters?.category;
    if (category === 'all') {
      return !currentFilter;
    }
    return currentFilter === category;
  };

  return (
    <div className="editor-header__tabs">
      <button
        type="button"
        className={`editor-header__tab ${isActive('all') ? '--active' : ''}`}
        onClick={() => handleCategoryClick('all')}
      >
        {t('All Categories')}
      </button>
      {STORE_CATEGORIES.map((category) => {
        const CategoryIcon = getStoreIcon(category);
        return (
          <button
            key={category}
            type="button"
            className={`editor-header__tab ${isActive(category) ? '--active' : ''}`}
            onClick={() => handleCategoryClick(category)}
          >
            <div className="tab-content">
              {CategoryIcon && <CategoryIcon className="tab-icon" />}
              <span className="tab-text">{category}</span>
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default CategoryFilter; 