import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Header } from '../../components/Header/Header';
import './style.scss';
import '../../styles/components/_sorting-container.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getReportingList } from '../../services/Saga/getReportingList/actions';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import MonthsComponent from '../../components/MonthsComponent';
import YearPicker from '../../components/YearPicker';
import { getAllMalls } from '../../services/Saga/filters/actions';
import {
  setFilters,
  setReportingList,
  setShowInactiveStores,
  setSelectedMall,
  setSelectedStore,
} from '../../services/Redux/reportingListReducer/action';
import { useNavigate, useLocation } from 'react-router-dom';
import ROUTES from '../../routes/route';
import StoresGrid from './components/StoresGrid';
import { getDateRange } from '../../services/Redux/reportingListReducer/index';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';

export const ReportingList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const reportingList = useSelector((state) => state.reportingListReducer.reportingList);
  const allMalls = useSelector((state) => state.filtersReducer.allMalls);
  const filters = useSelector((state) => state.reportingListReducer.filters);
  const selectedStore = useSelector((state) => state.reportingListReducer.selectedStore);
  const selectedMall = useSelector((state) => state.reportingListReducer.selectedMall);

  const [isLoading, setIsLoading] = useState(false);

  const {
    selectedMonth,
    selectedYear, 
    selectedMallId: mallId,
    order,
  } = filters;

  const fetchReportingList = useCallback(() => {
    const [start, end] = getDateRange(selectedMonth, selectedYear);
    if (!start || !end) return;
    
    console.log('ReportingList - Fetching reporting list:', {
      start: start.format('YYYY-MM-DD'),
      end: end.format('YYYY-MM-DD'),
      mallId,
      order,
      selectedMall: selectedMall?.name
    });

    setIsLoading(true);
    dispatch(setReportingList({ stores: [], total: 0 }));
    dispatch(getReportingList({
      date: [start, end],
      mallId,
      order,
      limit: 400,
      offset: 0
    }));
  }, [dispatch, selectedMonth, selectedYear, mallId, order, selectedMall]);

  useEffect(() => {
    fetchReportingList();
  }, [fetchReportingList]);

  // Add effect to fetch reporting list when mall changes
  useEffect(() => {
    if (mallId) {
      console.log('ReportingList - Mall changed, fetching reporting list:', {
        mallId,
        selectedMall: selectedMall?.name,
        selectedMonth,
        selectedYear
      });
      fetchReportingList();
    }
  }, [mallId, fetchReportingList, selectedMall, selectedMonth, selectedYear]);

  useEffect(() => {
    if (reportingList.length > 0) {
      console.log('ReportingList - Received new reporting list:', {
        count: reportingList.length,
        firstStore: reportingList[0],
        mallId,
        selectedMall: selectedMall?.name
      });
      setIsLoading(false);
    }
  }, [reportingList, mallId, selectedMall]);

  useEffect(() => {
    console.log('Fetching all malls');
    dispatch(getAllMalls({ order: 'ASC' }));
  }, [dispatch]);

  useEffect(() => {
    const currentDate = dayjs();
    
    // Initialize both month and year together
    if (!selectedMonth || !selectedYear) {
      const currentMonth = {
        name: currentDate.format('MMM'),
        number: currentDate.month() + 1
      };

      dispatch(setFilters({ 
        selectedMonth: currentMonth,
        selectedYear: currentDate.year(),
        order: 'NOT_REPORTED'
      }));
    }
  }, [selectedMonth, selectedYear, dispatch]);

  const handleDateChange = useCallback((year) => {
    dispatch(setFilters({ selectedYear: year }));
  }, [dispatch]);

  const navigateToIncome = useCallback((store) => {
    const [start, end] = getDateRange(selectedMonth, selectedYear);
    
    // Update the selected mall if it's different
    if (store.mallId !== filters.selectedMallId) {
      const storeMall = allMalls?.data?.find(m => m.id === store.mallId);
      if (storeMall) {
        dispatch(setSelectedMall(storeMall));
        dispatch(setFilters({ selectedMallId: storeMall.id }));
      }
    }

    // Update the selected store in Redux
    dispatch(setSelectedStore(store));

    navigate(ROUTES.REPORT_INCOME, {
      state: {
        store: {
          id: store.id,
          name: store.name,
          mallName: store.mallName,
          tax: store.tax,
          startDate: store.startDate,
          endDate: store.endDate,
        },
        selected: 'INCOME',
        date: [
          { $d: start.toDate() },
          { $d: end.toDate() }
        ],
      },
    });
  }, [navigate, selectedMonth, selectedYear, filters.selectedMallId, allMalls?.data, dispatch]);

  const loadMore = useCallback(() => {
    // Implement loadMore functionality if needed
  }, []);

  const handleSortChange = useCallback((newOrder) => {
    dispatch(setFilters({ order: newOrder }));
  }, [dispatch]);

  const currentDate = useMemo(() => {
    if (!selectedMonth || !selectedYear) return null;
    const [start] = getDateRange(selectedMonth, selectedYear);
    return start;
  }, [selectedMonth, selectedYear]);

  // Handle navigation state from Income page
  useEffect(() => {
    if (state?.fromIncome) {
      // Set the selected store and mall
      if (state.store) {
        dispatch(setSelectedStore(state.store));
        const storeMall = allMalls?.data?.find(m => m.id === state.store.mallId);
        if (storeMall) {
          dispatch(setSelectedMall(storeMall));
          dispatch(setFilters({ 
            selectedMallId: storeMall.id,
            selectedMonth: state.selectedMonth,
            selectedYear: state.selectedYear
          }));
        }
      }
    }
  }, [state, dispatch, allMalls?.data]);

  const handleBackNavigation = useCallback(() => {
    // If we have a selected store in Redux, go to income page
    if (selectedStore) {
      const [start, end] = getDateRange(selectedMonth, selectedYear);
      navigate(ROUTES.REPORT_INCOME, {
        state: {
          store: selectedStore,
          selected: 'INCOME',
          date: [
            { $d: start.toDate() },
            { $d: end.toDate() }
          ],
        },
      });
    } else {
      // Otherwise go to dashboard
      navigate(ROUTES.DASHBOARD.HOME);
    }
  }, [navigate, selectedStore, selectedMonth, selectedYear]);

  const handlePageNavigation = (direction) => {
    const pages = ['LIST', 'INCOME', 'OVERVIEW'];
    const currentPage = 'LIST';
    const currentIndex = pages.indexOf(currentPage);
    let nextIndex;

    if (direction === 'next') {
      nextIndex = currentIndex === pages.length - 1 ? 0 : currentIndex + 1;
    } else {
      nextIndex = currentIndex === 0 ? pages.length - 1 : currentIndex - 1;
    }

    const nextPage = pages[nextIndex];
    
    if (nextPage === 'INCOME' || nextPage === 'OVERVIEW') {
      if (selectedStore) {
        const [start, end] = getDateRange(selectedMonth, selectedYear);
        navigate(ROUTES.REPORT_INCOME, {
          state: {
            store: selectedStore,
            selected: 'INCOME',
            date: [
              { $d: start.toDate() },
              { $d: end.toDate() }
            ],
            isPreviewMode: nextPage === 'OVERVIEW'
          },
        });
      }
    }
  };

  return (
    <div className="reporting-list-pc">
      <Header 
        selected="LIST"
      />
      <div className="contentContainer">
        <div className="incomeSortingContainer">
          <div className="sortingCenter">
            <div className="center-content">
              <div className="page-navigation">
                <button 
                  className="nav-arrow"
                  onClick={() => handlePageNavigation('prev')}
                  title={t('Previous page')}
                >
                  <IoChevronBack size={20} />
                </button>
                <div className="page-indicator">
                  <span>{t('list')}</span>
                </div>
                <button 
                  className="nav-arrow"
                  onClick={() => handlePageNavigation('next')}
                  title={t('Next page')}
                >
                  <IoChevronForward size={20} />
                </button>
              </div>

              <div className="date-selection">
                <YearPicker
                  selectedYear={selectedYear}
                  onChange={handleDateChange}
                />
                <MonthsComponent
                  selectedMonth={selectedMonth}
                  selectedYear={selectedYear}
                />
              </div>

              <div></div>
            </div>
          </div>
        </div>
        <div className="scrollableContent">
          <div className="listWrapper">
            <StoresGrid 
              stores={reportingList}
              onStoreClick={navigateToIncome}
              loadMore={loadMore}
              isLoading={isLoading}
              currentDate={currentDate}
              filters={filters}
              onSortChange={handleSortChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
