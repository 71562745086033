import { cloneDeep } from 'lodash';
import { SORT } from '../const';
import { getNeededCellData, toNumber } from './getCellData';
import { getShouldDisplayDiff } from './getDiff';
import { getShownMalls, getShownStores } from './getShown';
import { getColumnTotal } from './getTotal';

const getDateKey = (from, to) => {
  const fromDate = new Date(from);
  const toDate = new Date(to);
  const padNumber = (num) => String(num).padStart(2, '0');
  return `${padNumber(fromDate.getDate())}-${padNumber(fromDate.getMonth() + 1)}-${fromDate.getFullYear()}_${padNumber(toDate.getDate())}-${padNumber(toDate.getMonth() + 1)}-${toDate.getFullYear()}`;
};

const sortTable = ({
  malls,
  sort,
  sortedByColumnUID,
  hiddenStores,
  columns,
  isEdit,
  hiddenMalls,
}) => {
  let sortedMalls = getShownMalls({
    malls,
    isEdit,
    hiddenMalls,
  });

  const shouldDisplayDiff = getShouldDisplayDiff(columns);

  const shownColumns = columns.filter((x) =>
    shouldDisplayDiff
      ? isEdit
        ? true
        : x.isVisible
      : x.isVisible && !x.isDiff,
  );

  const isSortingColumnShown = sortedByColumnUID
    ? !!shownColumns.find((x) => x.columnUID === sortedByColumnUID)?.isVisible
    : false;

  if (sort && sortedByColumnUID && isSortingColumnShown) {
    sortedMalls = (malls || [])
      .map((mall) => ({
        ...mall,
        sortedByTotal: getColumnTotal(
          getShownStores({
            stores: mall.stores,
            isEdit,
            hiddenStores,
          }),
          shownColumns.find((col) => col.columnUID === sortedByColumnUID),
          shownColumns.find((x) => x.isVisible),
        ),
      }))
      .sort((a, b) =>
        SORT[1] === sort
          ? a.sortedByTotal - b.sortedByTotal
          : b.sortedByTotal - a.sortedByTotal,
      );
  }

  sortedMalls = (sortedMalls || []).map((mall) => {
    let stores = getShownStores({
      stores: mall.stores,
      isEdit,
      hiddenStores,
    });

    // Sort stores by category first, then by name
    stores = stores.sort((a, b) => {
      const categoryA = a.category || 'Other retail';
      const categoryB = b.category || 'Other retail';
      
      // First sort by category
      if (categoryA !== categoryB) {
        return categoryA.localeCompare(categoryB);
      }
      
      // Then sort by name within the same category
      return a.name.localeCompare(b.name);
    });

    if (sort && sortedByColumnUID && isSortingColumnShown) {
      const sortingColumn = columns.find(
        (col) => col.columnUID === sortedByColumnUID,
      );

      if (sortingColumn)
        stores = stores.sort((a, b) => {
          const x = getNeededCellData(
            sortingColumn.isDiff
              ? sortingColumn.diffType === 'numbers'
                ? a.numberDiff
                : a.percentageDiff
              : a[getDateKey(sortingColumn.from, sortingColumn.to)],
            sortingColumn.isDiff
              ? shownColumns.find((x) => x.isVisible).type
              : sortingColumn.type,
            sortingColumn.isDiff
              ? shownColumns.find((x) => x.isVisible).tax
              : sortingColumn.tax,
          );
          const y = getNeededCellData(
            sortingColumn.isDiff
              ? sortingColumn.diffType === 'numbers'
                ? b.numberDiff
                : b.percentageDiff
              : b[getDateKey(sortingColumn.from, sortingColumn.to)],
            sortingColumn.isDiff
              ? shownColumns.find((x) => x.isVisible).type
              : sortingColumn.type,
            sortingColumn.isDiff
              ? shownColumns.find((x) => x.isVisible).tax
              : sortingColumn.tax,
          );

          const x1 = toNumber(x);
          const y1 = toNumber(y);

          return SORT[1] === sort ? x1 - y1 : y1 - x1;
        });
    }

    let sortedStoreColumns = shownColumns.reduce((cur, col) => {
      const mappedStores = Object.fromEntries(
        stores.map((store) => [
          store.id,
          {
            ...store,
            groups: store.groups || [],
            category: store.category || 'Other retail',
            cellData: getNeededCellData(
              col.isDiff
                ? col.diffType === 'numbers'
                  ? store.numberDiff
                  : store.percentageDiff
                : store[getDateKey(col.from, col.to)],
              col.isDiff
                ? shownColumns.find((x) => x.isVisible).type
                : col.type,
              col.isDiff ? shownColumns.find((x) => x.isVisible).tax : col.tax,
            ),
          },
        ]),
      );
      return { ...cur, [col.columnUID]: mappedStores };
    }, {});

    return { ...mall, sortedStoreColumns, shownStores: stores.map(store => ({
      ...store,
      groups: store.groups || [],
      category: store.category || 'Other retail'
    })) || [] };
  });

  return sortedMalls;
};

export const formatTablesSettingsToIncludeSortedMalls = (
  tablesSettings,
  getIsEditing = () => false,
) => {
  return Object.fromEntries(
    Object.keys(tablesSettings).map((tableUID) => {
      const tableSettings = tablesSettings[tableUID];
      const {
        columns,
        sort,
        sortedByColumnUID,
        malls = [],
        hiddenStores,
        hiddenMalls,
      } = tableSettings;

      const isEdit = getIsEditing(tableUID);

      return [
        tableUID,
        {
          ...tableSettings,
          sortedMalls:
            sortTable({
              malls,
              sort,
              sortedByColumnUID,
              hiddenStores,
              columns,
              isEdit,
              hiddenMalls,
            }) || [],
        },
      ];
    }),
  );
};

export default sortTable;
