import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const TypeSelector = ({ selectedType, onSelect }) => {
  const { t } = useTranslation();

  const types = [
    { id: 'income', label: t('income') },
    { id: 'averageTransaction', label: t('averageTransaction') },
    { id: 'transactions', label: t('transactions') }
  ];

  return (
    <div className="type-selector">
      <div className="type-selector__grid">
        {types.map(type => (
          <button
            key={type.id}
            className={`type-selector__button ${selectedType === type.id ? '--active' : ''}`}
            onClick={() => onSelect(type.id)}
          >
            {type.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TypeSelector; 