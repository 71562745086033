import axios from 'axios';
import dayjs from 'dayjs';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { MAIN_URL } from '../../../utils/constants';
import { setStoreIncome, setYearToDateIncome, setPreviousYearIncome, setError } from '../../Redux/storeIncomeReducer/action';
import { ActionTypes } from './actionsTypes';
import axiosInstance from '../../Axios/axiosInstance';

export function* watcherGetStoreIncome() {
  yield takeEvery(ActionTypes.GET_STORE_INCOME, workerGetStoreIncome);
}

export function* workerGetStoreIncome({ payload }) {
  try {
    const today = dayjs();
    let startDate = dayjs(payload.date[0]);
    let endDate = dayjs(payload.date[1]);

    // If the end date is in the future, set it to today
    if (endDate.isAfter(today)) {
      endDate = today;
    }

    // If start date is after end date, set it to the first day of the end date's month
    if (startDate.isAfter(endDate)) {
      startDate = endDate.startOf('month');
    }

    // If we're requesting future data, return empty array
    if (startDate.isAfter(today)) {
      if (payload.isYearToDate) {
        yield put(setYearToDateIncome([]));
      } else if (payload.isPreviousYear) {
        yield put(setPreviousYearIncome([]));
      } else {
        yield put(setStoreIncome([]));
      }
      return;
    }

    console.log('Fetching store income:', {
      payload,
      adjustedDates: {
        from: startDate.format('YYYY-MM-DD'),
        to: endDate.format('YYYY-MM-DD')
      },
      timestamp: new Date().toISOString()
    });

    const response = yield call(axiosInstance.get, `/incomes/stores/${payload.id}/reports`, {
      params: {
        from: startDate.format('YYYY-MM-DD'),
        to: endDate.format('YYYY-MM-DD'),
        limit: payload.limit || 366
      }
    });

    console.log('Store income response:', {
      data: response.data,
      isYearToDate: payload.isYearToDate,
      isPreviousYear: payload.isPreviousYear,
      timestamp: new Date().toISOString()
    });

    const responseData = Array.isArray(response.data) ? response.data : [];

    if (payload.isYearToDate) {
      yield put(setYearToDateIncome(responseData));
    } else if (payload.isPreviousYear) {
      yield put(setPreviousYearIncome(responseData));
    } else {
      yield put(setStoreIncome(responseData));
    }
  } catch (error) {
    console.error('Error fetching store income:', error);
    
    // Log the full error response for debugging
    if (error.response) {
      console.error('Error response:', {
        data: error.response.data,
        status: error.response.status,
        headers: error.response.headers
      });
    }
    
    yield put(setError(error.message || 'Failed to fetch store income'));
    
    // Set empty arrays for the respective data types to prevent UI errors
    if (payload.isYearToDate) {
      yield put(setYearToDateIncome([]));
    } else if (payload.isPreviousYear) {
      yield put(setPreviousYearIncome([]));
    } else {
      yield put(setStoreIncome([]));
    }
  }
}

export function* watcherAddIncome() {
  yield takeEvery(ActionTypes.ADD_INCOME, workerAddIncome);
}

export function* workerAddIncome({ payload }) {
  const body = {
    storeId: payload.id,
    included: payload.included,
    excluded: payload.excluded,
    date: dayjs(payload.date).format('YYYY-MM-DD'),
  };
  const putBody = {
    included: payload.included,
    excluded: payload.excluded,
  };
  const res = payload.isEmpty
    ? yield call(axiosInstance.put, `/incomes/${payload.isEmpty}`, putBody)
    : yield call(axiosInstance.post, `/incomes/`, body);
  return res;
}

export function* watcherExportIncome() {
  yield takeEvery(ActionTypes.EXPORT_INCOME, workerExportIncome);
}

export function* workerExportIncome({ payload }) {
  const token = yield select((state) => state?.loginReducer?.accessToken);
  const today = dayjs().format('YYYY-MM-DD');
  const startMonth = dayjs().startOf('month').format('YYYY-MM-DD');
  const start = !!payload.date
    ? payload.date[0].format('YYYY-MM-DD')
    : startMonth;
  const end = !!payload.date ? payload.date[1].format('YYYY-MM-DD') : today;
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    Authorization: `Bearer ${token}`,
  };
  axios({
    url: `${MAIN_URL}/v1/incomes/reports/exports/${payload.id}?from=${start}&to=${end}`,
    method: 'GET',
    headers,
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${payload.name}.xlsx`);
    document.body.appendChild(link);
    link.click();
  });
}
