export const getDiffNumbers = (firstValue, secondValue) => {
  if (firstValue === null || secondValue === null) return '-';
  return secondValue - firstValue;
};

export const calculateMoneyDiff = (store) => {
  const { id, name, startDate, endDate, ...dates } = store;

  const keys = Object.keys(dates);

  const isTheSameDate = keys.length === 1;
  const secondDateKey = isTheSameDate ? 0 : 1;

  if (!dates[keys[0]] || !dates[keys[secondDateKey]]) {
    return {
      averageTrade: {
        include: '-',
        exclude: '-'
      },
      averageTransaction: '-',
      income: {
        include: '-',
        exclude: '-'
      }
    };
  }

  return {
    averageTrade: {
      include: getDiffNumbers(
        dates[keys[0]]?.averageTrade?.include,
        dates[keys[secondDateKey]]?.averageTrade?.include,
      ),
      exclude: getDiffNumbers(
        dates[keys[0]]?.averageTrade?.exclude,
        dates[keys[secondDateKey]]?.averageTrade?.exclude,
      ),
    },
    averageTransaction: getDiffNumbers(
      dates[keys[0]]?.averageTransaction,
      dates[keys[secondDateKey]]?.averageTransaction,
    ),
    income: {
      include: getDiffNumbers(
        dates[keys[0]]?.income?.include,
        dates[keys[secondDateKey]]?.income?.include,
      ),
      exclude: getDiffNumbers(
        dates[keys[0]]?.income?.exclude,
        dates[keys[secondDateKey]]?.income?.exclude,
      ),
    },
  };
};

export const getDiffPercentage = (firstValue, secondValue) => {
  return typeof secondValue === 'number' &&
    typeof firstValue === 'number' &&
    firstValue !== 0
    ? firstValue !== null
      ? (((secondValue - firstValue) / firstValue) * 100).toFixed(2)
      : null
    : '-';
};

export const calculatePercentageDiff = (store) => {
  const { id, name, startDate, endDate, ...dates } = store;

  const keys = Object.keys(dates);
  const isTheSameDate = keys.length === 1;
  const secondDateKey = isTheSameDate ? 0 : 1;

  if (!dates[keys[0]] || !dates[keys[secondDateKey]]) {
    return {
      averageTrade: {
        include: '-',
        exclude: '-'
      },
      averageTransaction: '-',
      income: {
        include: '-',
        exclude: '-'
      }
    };
  }

  return {
    averageTrade: {
      include: getDiffPercentage(
        dates[keys[0]]?.averageTrade?.include,
        dates[keys[secondDateKey]]?.averageTrade?.include,
      ),
      exclude: getDiffPercentage(
        dates[keys[0]]?.averageTrade?.exclude,
        dates[keys[secondDateKey]]?.averageTrade?.exclude,
      ),
    },
    averageTransaction: getDiffPercentage(
      dates[keys[0]]?.averageTransaction,
      dates[keys[secondDateKey]]?.averageTransaction,
    ),
    income: {
      include: getDiffPercentage(
        dates[keys[0]]?.income?.include,
        dates[keys[secondDateKey]]?.income?.include,
      ),
      exclude: getDiffPercentage(
        dates[keys[0]]?.income?.exclude,
        dates[keys[secondDateKey]]?.income?.exclude,
      ),
    },
  };
};

export const getShouldDisplayDiff = (columns) => {
  if (!columns) return false;
  const filteredColumns = columns
    .filter((col) => col.isVisible)
    .filter((col) => !col.isDiff);
  return (
    filteredColumns.length === 2 &&
    filteredColumns[0].tax === filteredColumns[1].tax &&
    filteredColumns[0].type === filteredColumns[1].type
  );
};
