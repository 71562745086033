import {
  FoodBeverageIcon,
  FashionTravelIcon,
  SpecialStoresIcon,
  HouseHomeIcon,
  ServicesIcon,
  ServingIcon,
  OtherRetailIcon,
  OtherBusinessIcon
} from '../../../assets/icons/categories';

// Map backend names to icons - these must match EXACTLY with backend/src/common/constants/category.constant.js
const iconMap = {
  'Food and Drinks': FoodBeverageIcon,
  'Fashion & Travel': FashionTravelIcon,
  'Special Stores': SpecialStoresIcon,
  'House goods': HouseHomeIcon,
  'Services': ServicesIcon,
  'Serving': ServingIcon,
  'Other retail': OtherRetailIcon,
  'Other Business': OtherBusinessIcon
};

export const getStoreIcon = (category) => {
  if (!category) {
    return OtherRetailIcon;
  }

  // Return the found icon or default to OtherRetailIcon
  return iconMap[category] || OtherRetailIcon;
}; 