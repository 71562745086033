import React from 'react';

const MallList = ({ malls, activeMallIds, selectedMallId, handleMallClick }) => {
  // Group malls by first letter
  const groupedMalls = malls.reduce((groups, mall) => {
    const firstLetter = mall.name.charAt(0).toUpperCase();
    if (!groups[firstLetter]) {
      groups[firstLetter] = [];
    }
    groups[firstLetter].push(mall);
    return groups;
  }, {});

  const isActiveMall = (mallId) => {
    return activeMallIds instanceof Set 
      ? activeMallIds.has(mallId)
      : Array.isArray(activeMallIds) && activeMallIds.includes(mallId);
  };

  return (
    <div className="retail-editor__malls">
      {Object.entries(groupedMalls).map(([letter, mallGroup]) => (
        <div key={letter} className="retail-editor__mall-group">
          {mallGroup.map(mall => {
            const isActive = isActiveMall(mall.id);
            const isSelected = selectedMallId === mall.id;
            const classes = [
              'retail-editor__mall-item',
              isSelected ? 'selected' : '',
              isActive ? 'active' : 'inactive'
            ].filter(Boolean).join(' ');

            return (
              <div
                key={mall.id}
                className={classes}
                onClick={() => handleMallClick(mall.id)}
              >
                <div className="status-circle" />
                <div className="mall-name">
                  <span>{mall.name}</span>
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default MallList; 