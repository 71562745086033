import React, { useContext } from 'react';
import { IncomeTableContext } from '../../IncomeTableContext';
import { useTranslation } from 'react-i18next';
import { TableContext } from '../Table/TableContext';
import addSpaces from '../../../../utils/addSpaces';
import { getIsStoreDisabled } from '../../utils/getDisabledStore';
import { shouldDisplayPercents, getIsStoreShown } from '../../utils/otherUtils';
import dayjs from 'dayjs';
import Triangle from '../../../../assets/icons/Triangle';
import ToggleableEye from '../ToggleableEye';
import './styles.scss';

// Sort columns from oldest to most recent (left to right)
const sortColumnsByDate = (columns) => {
  return [...columns].sort((a, b) => {
    if (a.isDiff || b.isDiff) return 0;
    const dateA = dayjs(a.from);
    const dateB = dayjs(b.from);
    return dateA.valueOf() - dateB.valueOf(); // Oldest first
  });
};

function TableMallComponent({ mall, onOpenEditor }) {
  const { t } = useTranslation();
  const { toggleStoreVisible, toggleMallVisible, toggleMallMinimized } = useContext(IncomeTableContext);
  const { tableUID, tableSettings, isEdit, shownColumns } = useContext(TableContext);

  const { sortedStoreColumns = {}, shownStores = [] } = mall;
  const shouldDisplayAdd = shownColumns.filter(x => !x.isDiff).length < 6;

  const { minimizedMalls, hiddenMalls } = tableSettings;
  const isMinimized = minimizedMalls?.find((x) => x === mall?.id);

  if (hiddenMalls.find((x) => x === mall.id)) return <></>;

  // Get disabled stores
  const disabledStores = shownStores.filter(store => {
    return Object.keys(sortedStoreColumns).some(columnUID => {
      return getIsStoreDisabled({
        shownColumns,
        columnUID,
        sortedStoreColumns,
        storeId: store.id
      });
    });
  }).map(store => store.id);

  // Sort columns to match header order
  const sortedColumns = sortColumnsByDate(shownColumns);
  const sortedColumnUIDs = sortedColumns.map(col => col.columnUID);

  // Filter active stores
  const activeStores = shownStores.filter((store) => !disabledStores.find((x) => x === store.id));

  const handleMallClick = () => {
    onOpenEditor('retail', mall.id);
  };

  return (
    <div id={mall?.id} className="TableMallComponent">
      {/* MALL HEADER */}
      <div className='TableMallComponent__header'>
        <div 
          className="triangle-wrapper"
          onClick={() => toggleMallMinimized(tableUID, mall.id)}
        >
          <Triangle
            className='triangle'
            style={{ transform: `rotate(${isMinimized ? 180 : 90}deg)` }}
          />
        </div>
        <div className='TableMallComponent__mallName'>
          <div 
            className="content-wrapper"
            onClick={handleMallClick}
            style={{ cursor: 'pointer' }}
          >
            <div className={tableSettings.hiddenMalls.find((x) => x === mall.id) ? 'hidden' : 'mallName'}>
              {mall.name}
            </div>
          </div>
        </div>
      </div>

      {/* MALL STORE DATA */}
      {!isMinimized && (
        <div className="TableMallComponent__content">
          {/* Store Names */}
          <div className="TableMallComponent__stores">
            {activeStores.map((store) => (
              <div key={store.id} className='TableMallComponent__storeName'>
                <span className={getIsStoreShown(tableSettings, null, store.id, mall.id)}>
                  {store.name}
                </span>
              </div>
            ))}
          </div>

          {/* Store Data */}
          <div className="TableMallComponent__data">
            {sortedColumnUIDs.map((columnUID) => (
              <div key={columnUID} className='TableMallComponent__column'>
                {activeStores.map((store) => {
                  const storeData = sortedStoreColumns[columnUID][store.id];
                  const isDisabled = getIsStoreDisabled({
                    shownColumns,
                    columnUID,
                    sortedStoreColumns,
                    storeId: store.id,
                  });
                  const column = shownColumns.find((x) => x.columnUID === columnUID);
                  const cellData = isDisabled && !column.isDiff
                    ? t('notActive')
                    : addSpaces(storeData.cellData, { hideSecond: true });

                  return (
                    <div
                      key={store.id}
                      className={`TableMallComponent__cell ${getIsStoreShown(
                        tableSettings,
                        columnUID,
                        store.id,
                        mall.id,
                      )}`}
                    >
                      {cellData}
                      {shouldDisplayPercents(
                        shownColumns,
                        columnUID,
                        cellData,
                      ) && ' %'}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default TableMallComponent;
