import { store } from '../index';
import { logOutRed, setUser } from '../Redux/loginReducer/action';
import * as ReactDOM from 'react-dom';
import { SessionExpiredAlert } from '../../components/SessionExpiredAlert';
import ROUTES from '../../routes/route';
import { logTokenInfo } from '../../utils/tokenDebug';
import { getI18n } from 'react-i18next';
import showNotification from '../../utils/showNotification';

let isRefreshing = false;
let refreshSubscribers = [];

const onRequest = async (config, refreshInstance) => {
  if (config.url?.includes('auth/')) {
    return config;
  }

  try {
    const { accessToken, refreshToken } = store.getState().loginReducer;
    if (!accessToken || !refreshToken) {
      if (!config.url?.includes('auth/')) {
        await handleSessionExpiration();
        return Promise.reject(new Error('No tokens available'));
      }
      return config;
    }

    if (shouldRefreshToken() && !isRefreshing) {
      isRefreshing = true;
      
      try {
        const response = await refreshInstance.put('/auth/refresh-tokens', {}, {
          headers: { 'x-refresh-token': refreshToken }
        });

        const { accessToken: newAccessToken, refreshToken: newRefreshToken } = response.data;
        store.dispatch(setUser({ accessToken: newAccessToken, refreshToken: newRefreshToken }));
        
        config.headers.Authorization = `Bearer ${newAccessToken}`;
        refreshSubscribers.forEach(cb => cb(newAccessToken));
        refreshSubscribers = [];
      } catch (error) {
        refreshSubscribers = [];
        await handleSessionExpiration(error);
        throw error;
      } finally {
        isRefreshing = false;
      }
    }

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  } catch (error) {
    return Promise.reject(error);
  }
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

const onResponse = (response) => {
  return response.data;
};

const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

const onResponseError = async (error, axiosInstance, refreshInstance) => {
  const originalRequest = error.config;
  
  if (error.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    
    const refreshToken = store.getState().loginReducer.refreshToken;
    
    const isExpiredToken = error.response?.data?.code === 401005;
    
    if (!isExpiredToken) {
      console.group('Token Refresh Debug');
      console.log('401 error intercepted:', {
        url: originalRequest.url,
        timestamp: new Date().toISOString(),
        hasRefreshToken: !!refreshToken,
        isRefreshing
      });
    }

    if (!refreshToken) {
      if (!isExpiredToken) console.groupEnd();
      await handleSessionExpiration(error);
      return Promise.reject(error);
    }
    
    if (isRefreshing) {
      try {
        const accessToken = await new Promise(resolve => {
          addRefreshSubscriber(resolve);
        });
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        return axiosInstance(originalRequest);
      } catch (error) {
        return Promise.reject(error);
      }
    }

    if (!isRefreshing) {
      isRefreshing = true;
      
      try {
        const response = await refreshInstance.put('auth/refresh-tokens', {}, {
          headers: { 'x-refresh-token': refreshToken }
        });
        
        if (!isExpiredToken) {
          console.log('Token refresh successful');
        }
        
        const { accessToken, refreshToken: newRefreshToken } = response.data;
        
        store.dispatch(setUser({
          accessToken,
          refreshToken: newRefreshToken
        }));
        
        if (!isExpiredToken) {
          logTokenInfo();
        }
        
        refreshSubscribers.forEach(cb => cb(accessToken));
        refreshSubscribers = [];
        
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        
        if (!isExpiredToken) console.groupEnd();
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        refreshSubscribers = [];
        
        if (!isExpiredToken) {
          console.error('Token refresh failed:', refreshError?.response?.data || refreshError);
        }
        
        await handleSessionExpiration(refreshError);
        
        if (!isExpiredToken) console.groupEnd();
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }
  }
  
  return Promise.reject(error);
};

const setupInterceptorsTo = (axiosInstance, refreshInstance) => {
  axiosInstance.interceptors.request.use(
    config => onRequest(config, refreshInstance),
    onRequestError
  );

  axiosInstance.interceptors.response.use(
    response => onResponse(response),
    error => onResponseError(error, axiosInstance, refreshInstance)
  );

  return axiosInstance;
};

const shouldRefreshToken = () => {
  const { accessToken } = store.getState().loginReducer;
  if (!accessToken) return false;
  
  try {
    const decoded = JSON.parse(atob(accessToken.split('.')[1]));
    const expiresAt = decoded.exp * 1000;
    const refreshWindow = (decoded.refreshWindow || 45) * 1000;
    return Date.now() >= (expiresAt - refreshWindow);
  } catch {
    return false;
  }
};

export const handleSessionExpiration = (error) => {
  return new Promise((resolve) => {
    // Just dispatch logout and redirect
    store.dispatch(logOutRed(error?.response?.data));
    window.location.href = ROUTES.AUTH.LOGIN;
    resolve();
  });
};

export default setupInterceptorsTo;
