import React, { createContext, useContext } from 'react';
import { IncomeTableContext } from '../../IncomeTableContext';
import { getShouldDisplayDiff } from '../../utils/getDiff';

export const TableContext = createContext({});

export const TableProvider = ({ tableUID, children }) => {
  const { tablesSettings, selectedTable, isEditing, selectedColumn } =
    useContext(IncomeTableContext);
  const tableSettings = tablesSettings[tableUID];

  const { columns } = tableSettings;

  const isEdit = isEditing && tableUID === selectedTable;

  const shouldDisplayDiff = getShouldDisplayDiff(columns);

  const shownColumns = columns.filter((x) => {
    if (!shouldDisplayDiff) return x.isVisible && !x.isDiff;
    if (x.isDiff) return x.isVisible;
    return x.isVisible;
  });

  const numbersDiffColumn = columns.find((col) => col.diffType === 'numbers');
  const percentageDiffColumn = columns.find(
    (col) => col.diffType === 'percentage',
  );

  const shouldDisplayAdd =
    isEdit && columns.filter((x) => !x.isDiff).length < 6;

  // Get all malls for the editor, including hidden ones
  const allMalls = tableSettings.malls || [];
  // Get visible malls for display
  const visibleMalls = tableSettings.sortedMalls || [];

  return (
    <TableContext.Provider
      value={{
        tableUID,
        malls: allMalls,
        visibleMalls,
        tableSettings,
        isEdit,
        shownColumns,
        numbersDiffColumn,
        percentageDiffColumn,
        shouldDisplayAdd,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};
