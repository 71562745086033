import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const TaxSelector = ({ selectedTax, onSelect }) => {
  const { t } = useTranslation();

  const taxOptions = [
    { id: 'included', label: t('vat_display_included') },
    { id: 'excluded', label: t('vat_display_excluded') }
  ];

  return (
    <div className="tax-selector">
      <div className="tax-selector__grid">
        {taxOptions.map(option => (
          <button
            key={option.id}
            className={`tax-selector__button ${selectedTax === option.id ? '--active' : ''}`}
            onClick={() => onSelect(option.id)}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TaxSelector; 