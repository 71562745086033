import React from 'react';
import { useTranslation } from 'react-i18next';
import TrashIcon from '../../../../../../../assets/icons/TrashIcon';
import CategoryFilter from '../../../RetailEditor/components/CategoryFilter/CategoryFilter';
import './styles.scss';

const EditorHeader = ({
  activeTab,
  onTabChange,
  onDeleteTable,
  activeEditor = 'columns',
  onEditorChange,
  searchQuery,
  onSearchChange,
  tableUID,
  tableSettings,
  updateFilters,
  selectedMallId,
  selectedMallData,
  allStores,
}) => {
  const { t } = useTranslation();

  const columnTabs = [
    { key: 'picker', label: t('editor_tab_calendar') },
    { key: 'presets', label: t('editor_tab_presets') },
    { key: 'types', label: t('editor_tab_type') },
    { key: 'tax', label: t('editor_tab_vat') },
  ];

  return (
    <div className="editor-header">
      <div className="editor-header__main">
        <div className="editor-header__sections">
          <button
            type="button"
            className={`editor-header__section-button ${activeEditor === 'retail' ? '--active' : ''}`}
            onClick={() => onEditorChange('retail')}
          >
            {t('Retail')}
          </button>
          <button
            type="button"
            className={`editor-header__section-button ${activeEditor === 'columns' ? '--active' : ''}`}
            onClick={() => onEditorChange('columns')}
          >
            {t('Columns')}
          </button>
        </div>

        <div className="editor-header__actions">
          {activeEditor === 'retail' && (
            <div className="editor-header__search">
              <svg 
                className="search-icon" 
                width="12" 
                height="12" 
                viewBox="0 0 24 24" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
              >
                <path 
                  d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                />
              </svg>
              <input
                type="search"
                placeholder={t('Search stores...')}
                value={searchQuery}
                onChange={(e) => onSearchChange(e.target.value)}
                className="editor-header__search-input"
              />
            </div>
          )}
          <button
            type="button"
            className="editor-header__delete"
            onClick={onDeleteTable}
          >
            <TrashIcon />
          </button>
        </div>
      </div>

      {activeEditor === 'columns' && (
        <div className="editor-header__sub">
          <div className="editor-header__tabs">
            {columnTabs.map(tab => (
              <button
                key={tab.key}
                type="button"
                className={`editor-header__tab ${activeTab === tab.key ? '--active' : ''}`}
                onClick={() => onTabChange(tab.key)}
              >
                {tab.label}
              </button>
            ))}
          </div>
        </div>
      )}

      {activeEditor === 'retail' && (
        <div className="editor-header__sub">
          <CategoryFilter
            tableUID={tableUID}
            tableSettings={tableSettings}
            updateFilters={updateFilters}
            stores={selectedMallId ? selectedMallData?.stores : allStores}
          />
        </div>
      )}
    </div>
  );
};

export default EditorHeader;
// ... existing code ... 