import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import HideDown from '../../assets/icons/HideDown';
import { Header } from '../../components/Header/Header';
import { setVisibleCharts } from '../../services/Redux/leftMenuReducer/action';
import './style.scss';
import HomePageCards from './components/TopCard';
import IncomeTable from '../../components/IncomeTable';
import { IncomeTableProvider } from '../../components/IncomeTable/IncomeTableContext';

export const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [color, setColor] = useState('#FAFAFA');
  
  const visibleCharts = useSelector(state => state.leftMenuReducer.visibleCharts);
  const isMenuHidden = useSelector(state => state.headerReducer.isMenuHidden);

  return (
    <div className="home">
      <IncomeTableProvider>
        <>
          <Header selected="HOME" />
          <div className="mainContainer">
            <div className={`contentContainer ${!isMenuHidden ? 'menu-visible' : ''}`}>
              <div className="scrollableContent">
                {visibleCharts ? (
                  <HomePageCards />
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div
                      className='chartsContainer'
                      onMouseEnter={() => setColor('rgba(218, 253, 0, 1)')}
                      onMouseLeave={() => setColor('#FAFAFA')}
                    >
                      <text className='chartsTitle'>{t('charts')}</text>
                      <button
                        className='chartsButton'
                        onClick={() => dispatch(setVisibleCharts(true))}
                      >
                        <HideDown color={color} />
                      </button>
                    </div>
                  </div>
                )}
                <IncomeTable />
              </div>
            </div>
          </div>
        </>
      </IncomeTableProvider>
    </div>
  );
};
