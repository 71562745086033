import dayjs from 'dayjs';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Header } from '../../components/Header/Header';
import { getStoreIncome } from '../../services/Saga/storeIncome/actions';
import './style.scss';
import MonthsComponent from '../../components/MonthsComponent';
import IncomeCalendarCell from './components/IncomeCalendarCell';
import YearPicker from '../../components/YearPicker';
import { ROLES } from '../AdminPanel/utils/formatForTables';
import ROUTES from '../../routes/route';
import { setFilters } from '../../services/Redux/reportingListReducer/action';
import { getDateRange } from '../../services/Redux/reportingListReducer/index';
import { IncomeInsights } from './components/IncomeInsights/IncomeInsights';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

export const Income = () => {
  const { t } = useTranslation();
  const [date, setDate] = useState(null);
  const { state } = useLocation();
  const [filteredData, setFilteredData] = useState([]);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [showIncludedVAT, setShowIncludedVAT] = useState(true);
  const [showBrutto, setShowBrutto] = useState(true);
  const [showNetto, setShowNetto] = useState(false);
  const user = useSelector((state) => state.loginReducer.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedMonth, selectedYear } = useSelector(state => state.reportingListReducer.filters);
  const storeIncome = useSelector(state => state.storeIncomeReducer.storeIncome);
  const isStoreManager = user?.roles?.[0] === ROLES.STORE_MANAGER;
  const prevFetchKey = React.useRef(null);
  const [yearToDateRange, setYearToDateRange] = useState(null);
  const [previousYearRange, setPreviousYearRange] = useState(null);
  const [activeCell, setActiveCell] = useState({ weekIndex: null, index: null, field: null });

  // Log component mount and unmount
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log('Income Page - Initialized', {
        store: state?.store?.name || user?.store?.name,
        selectedMonth,
        selectedYear,
        isPreviewMode
      });

      return () => {
        console.log('Income Page - Cleanup', {
          store: state?.store?.name || user?.store?.name,
          selectedMonth,
          selectedYear
        });
      };
    }
  }, []);

  // Log only significant state changes
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      // Log only when important data changes
      if (storeIncome?.data?.length > 0) {
        console.log('Income Page - Data Update', {
          dataCount: storeIncome.data.length,
          selectedMonth,
          selectedYear,
          isPreviewMode
        });
      }
    }
  }, [storeIncome.data, selectedMonth, selectedYear, isPreviewMode]);

  // Add click outside handler to clear active cell
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if click is outside any calendar cell
      const isOutsideCell = !event.target.closest('.IncomeCalendarCell');
      if (isOutsideCell) {
        setActiveCell({ weekIndex: null, index: null, field: null });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // If no store is selected and we're not a store manager, redirect to reporting list
  useEffect(() => {
    if (!isStoreManager && !state?.store) {
      navigate(ROUTES.REPORTING_LIST);
    }
  }, [isStoreManager, state, navigate]);

  useEffect(() => {
    let filteredThisYear = [];
    const filteredPreviousYear = [];
    let filtered = [];
    const currentYear = date?.[0]?.$y || dayjs().$y;
    const currentMonth = date?.[0]?.month() || dayjs().month();

    // Only filter data for the current month when not in preview mode
    const arrayThisYear = storeIncome?.data?.filter(incomeEntry => {
      const entryDate = dayjs(incomeEntry.date);
      const isCurrentYear = entryDate.year() === currentYear;
      const isCurrentMonth = !isPreviewMode ? entryDate.month() === currentMonth : true;
      return isCurrentYear && isCurrentMonth;
    });

    const arrayPreviousYear = storeIncome?.data?.filter(
      incomeEntry => dayjs(incomeEntry.date).year() === currentYear - 1
    );

    // Filter out week 52/53 entries that belong to January
    const filteredArrayThisYear = arrayThisYear?.filter(incomeEntry => {
      const entryDate = dayjs(incomeEntry.date);
      if ([53, 52].includes(incomeEntry?.week) && entryDate.month() === 0) {
        return false;
      }
      return true;
    });

    // Add week 52/53 entries from January to previous year
    arrayPreviousYear?.push(
      ...arrayThisYear?.filter(incomeEntry => {
        const entryDate = dayjs(incomeEntry.date);
        return [53, 52].includes(incomeEntry?.week) && entryDate.month() === 0;
      })
    );

    // Group entries by week
    for (let index = 0; index <= 53; index++) {
      const weekEntries = filteredArrayThisYear?.filter(incomeEntry => incomeEntry.week === index);
      if (weekEntries?.length) {
        filteredThisYear.push({
          key: index,
          data: weekEntries.map(incomeEntry => ({
            ...incomeEntry,
            date: new Date(incomeEntry.date)
          }))
        });
      }
    }

    // Handle special case of week 52 and week 1 overlap
    const has52WeekAnd1Week =
      filteredArrayThisYear?.some(entry => entry.week === 52) &&
      filteredArrayThisYear?.some(entry => entry.week === 1);

    if (has52WeekAnd1Week) {
      const week1Entries = filteredThisYear.filter(x => x.key === 1);
      filteredThisYear = filteredThisYear.filter(x => x.key !== 1);
      filteredThisYear.push(...week1Entries);
    }

    // Process previous year data
    for (let index = 53; index > 0; index--) {
      const weekEntries = arrayPreviousYear?.filter(incomeEntry => incomeEntry.week === index);
      if (weekEntries?.length) {
        filteredPreviousYear.push({
          key: index,
          data: weekEntries.map(incomeEntry => ({
            ...incomeEntry,
            date: new Date(incomeEntry.date)
          }))
        });
      }
    }

    filtered = [...filteredPreviousYear.reverse(), ...filteredThisYear];

    // Only update filtered data if it actually changed
    const currentFilteredString = JSON.stringify(filtered);
    const prevFilteredString = JSON.stringify(filteredData);
    
    if (currentFilteredString !== prevFilteredString) {
      console.log('Income - Filtered Data Update:', {
        weekCount: filtered.length,
        firstWeek: filtered[0]?.key,
        lastWeek: filtered[filtered.length - 1]?.key,
        isPreviewMode,
        selectedMonth: selectedMonth.name,
        timestamp: new Date().toISOString()
      });
      
      setFilteredData(filtered);
    }
  }, [storeIncome.data, date, isPreviewMode]);

  // Handle navigation state
  useEffect(() => {
    console.log('Income Page - State Initialization:', {
      page: 'Income',
      navigationState: state,
      selectedMonth,
      selectedYear,
      timestamp: new Date().toISOString()
    });

    // Set preview mode from navigation state
    if (state?.isPreviewMode !== undefined) {
      setIsPreviewMode(state.isPreviewMode);
    }

    // Only initialize from navigation state if we don't have existing filters
    if (state?.date?.[0]?.$d && (!selectedMonth || !selectedYear)) {
      const dateStart = dayjs(state.date[0].$d);
      const newMonth = {
        name: dateStart.format('MMM'),
        number: dateStart.month() + 1
      };
      
      console.log('Income Page - Initial Filter Setup:', {
        page: 'Income',
        newMonth,
        newYear: dateStart.year(),
        timestamp: new Date().toISOString()
      });
      
      dispatch(setFilters({
        selectedMonth: newMonth,
        selectedYear: dateStart.year()
      }));
    }
  }, [state, dispatch]);

  // Handle all date-related state updates in a single effect
  useEffect(() => {
    const newDate = getDateRange(selectedMonth, selectedYear, isPreviewMode);
    const newYearToDateRange = getDateRange({ number: 12 }, selectedYear, true);
    const newPreviousYearRange = getDateRange({ number: 12 }, selectedYear - 1, true);

    const hasDateChanged = !date || !date[0]?.isSame(newDate[0]) || !date[1]?.isSame(newDate[1]);
    const hasYearToDateRangeChanged = !yearToDateRange || !yearToDateRange[0]?.isSame(newYearToDateRange[0]) || !yearToDateRange[1]?.isSame(newYearToDateRange[1]);
    const hasPreviousYearRangeChanged = !previousYearRange || !previousYearRange[0]?.isSame(newPreviousYearRange[0]) || !previousYearRange[1]?.isSame(newPreviousYearRange[1]);

    if (hasDateChanged || hasYearToDateRangeChanged || hasPreviousYearRangeChanged) {
      // Update all date ranges at once to prevent multiple re-renders
      // Important: Update yearToDateRange and previousYearRange first
      setYearToDateRange(newYearToDateRange);
      setPreviousYearRange(newPreviousYearRange);
      
      // Then update the main date range
      setDate(newDate);

      // Clear filtered data after all date ranges are set
      setFilteredData([]);

      console.log('Income - Date Range Update:', {
        from: newDate[0].format('YYYY-MM-DD'),
        to: newDate[1].format('YYYY-MM-DD'),
        isPreviewMode,
        selectedMonth: selectedMonth.name,
        selectedYear,
        yearToDateFrom: newYearToDateRange[0].format('YYYY-MM-DD'),
        yearToDateTo: newYearToDateRange[1].format('YYYY-MM-DD'),
        previousYearFrom: newPreviousYearRange[0].format('YYYY-MM-DD'),
        previousYearTo: newPreviousYearRange[1].format('YYYY-MM-DD'),
        showBrutto,
        showNetto,
        showIncludedVAT,
        timestamp: new Date().toISOString()
      });
    }
  }, [selectedMonth, selectedYear, isPreviewMode, date, yearToDateRange, previousYearRange, showBrutto, showNetto, showIncludedVAT]);

  // Fetch data only when necessary
  useEffect(() => {
    if (!date || (!state?.store?.id && !user.storeId)) return;

    const storeId = state?.store?.id || user.storeId;
    const limit = isPreviewMode ? 366 : 31;
    const dateFrom = date[0].format('YYYY-MM-DD');
    const dateTo = date[1].format('YYYY-MM-DD');

    // Check if we need to fetch new data
    const shouldFetch = !storeIncome.data || 
      storeIncome.data.length === 0 ||
      (isPreviewMode && storeIncome.data.length <= 31) ||
      (!isPreviewMode && storeIncome.data.length > 31) ||
      // Check if date range has changed
      (storeIncome.data.length > 0 && (
        dayjs(storeIncome.data[0].date).format('YYYY-MM-DD') !== dateFrom ||
        dayjs(storeIncome.data[storeIncome.data.length - 1].date).format('YYYY-MM-DD') !== dateTo
      ));

    if (shouldFetch) {
      console.log('Income - Fetching data:', {
        storeId,
        dateRange: [dateFrom, dateTo],
        limit,
        reason: 'Data length mismatch or date range changed',
        currentData: storeIncome.data?.length ? {
          firstDate: storeIncome.data[0].date,
          lastDate: storeIncome.data[storeIncome.data.length - 1].date
        } : null,
        showBrutto,
        showNetto,
        showIncludedVAT,
        timestamp: new Date().toISOString()
      });

      dispatch(
        getStoreIncome({
          id: storeId,
          date: [dateFrom, dateTo],
          limit,
          tax: showIncludedVAT ? 'included' : 'excluded'
        })
      );
    }
  }, [date, state?.store?.id, user.storeId, isPreviewMode, showIncludedVAT]);

  // Fetch year to date and previous year data only when in preview mode
  useEffect(() => {
    if (!isPreviewMode || !state?.store?.id && !user.storeId) return;
    if (!yearToDateRange || !previousYearRange) return;

    const storeId = state?.store?.id || user.storeId;
    const yearToDateFrom = yearToDateRange[0].format('YYYY-MM-DD');
    const yearToDateTo = yearToDateRange[1].format('YYYY-MM-DD');
    const prevYearFrom = previousYearRange[0].format('YYYY-MM-DD');
    const prevYearTo = previousYearRange[1].format('YYYY-MM-DD');

    // Create a unique key for this data fetch to prevent duplicates
    const fetchKey = `${yearToDateFrom}-${yearToDateTo}-${prevYearFrom}-${prevYearTo}-${showIncludedVAT}`;

    if (fetchKey !== prevFetchKey.current) {
      prevFetchKey.current = fetchKey;

      // Fetch year to date data
      console.log('Income - Fetching year to date data:', {
        storeId,
        yearToDateRange: [yearToDateFrom, yearToDateTo],
        isPreviewMode,
        showIncludedVAT,
        timestamp: new Date().toISOString()
      });

      dispatch(
        getStoreIncome({
          id: storeId,
          date: [yearToDateFrom, yearToDateTo],
          isYearToDate: true,
          limit: 366,
          tax: showIncludedVAT ? 'included' : 'excluded'
        })
      );

      // Fetch previous year data
      console.log('Income - Fetching previous year data:', {
        storeId,
        previousYearRange: [prevYearFrom, prevYearTo],
        isPreviewMode,
        showIncludedVAT,
        timestamp: new Date().toISOString()
      });

      dispatch(
        getStoreIncome({
          id: storeId,
          date: [prevYearFrom, prevYearTo],
          isPreviousYear: true,
          limit: 366,
          tax: showIncludedVAT ? 'included' : 'excluded'
        })
      );
    }
  }, [yearToDateRange, previousYearRange, state?.store?.id, user.storeId, isPreviewMode, showIncludedVAT, dispatch]);

  const handleShowFieldsChange = ({ showBrutto: newShowBrutto, showNetto: newShowNetto }) => {
    setShowBrutto(newShowBrutto);
    setShowNetto(newShowNetto);
  };

  const handleTabToNextCell = (currentWeekIndex, currentIndex, currentField) => {
    // If both fields are shown and we're on the first field
    if (showBrutto && showNetto && currentField === 'excluded') {
      setActiveCell({ weekIndex: currentWeekIndex, index: currentIndex, field: 'included' });
      return;
    }

    // Find next cell
    const currentWeek = filteredData[currentWeekIndex];
    if (currentIndex + 1 < currentWeek.data.length) {
      // Move to next cell in same week
      setActiveCell({ weekIndex: currentWeekIndex, index: currentIndex + 1, field: showNetto ? 'excluded' : 'included' });
    } else if (currentWeekIndex + 1 < filteredData.length) {
      // Move to first cell of next week
      setActiveCell({ weekIndex: currentWeekIndex + 1, index: 0, field: showNetto ? 'excluded' : 'included' });
    }
  };

  const handleBackToReporting = () => {
    // Use the current store and date information for navigation
    navigate(ROUTES.REPORTING_LIST, {
      state: {
        fromIncome: true,
        store: state?.store || user?.store,
        date: [
          { $d: date[0].toDate() },
          { $d: date[1].toDate() }
        ],
        selectedMonth,
        selectedYear
      }
    });
  };

  const handlePageNavigation = (direction) => {
    // For store managers, only toggle between INCOME and OVERVIEW
    if (isStoreManager) {
      setIsPreviewMode(!isPreviewMode);
      return;
    }

    // For other users, cycle through all pages
    const pages = ['LIST', 'INCOME', 'OVERVIEW'];
    const currentPage = isPreviewMode ? 'OVERVIEW' : 'INCOME';
    const currentIndex = pages.indexOf(currentPage);
    let nextIndex;

    if (direction === 'next') {
      nextIndex = currentIndex === pages.length - 1 ? 0 : currentIndex + 1;
    } else {
      nextIndex = currentIndex === 0 ? pages.length - 1 : currentIndex - 1;
    }

    const nextPage = pages[nextIndex];
    
    if (nextPage === 'LIST') {
      navigate(ROUTES.REPORTING_LIST);
    } else if (nextPage === 'INCOME') {
      setIsPreviewMode(false);
    } else if (nextPage === 'OVERVIEW') {
      setIsPreviewMode(true);
    }
  };

  // Update onYearChange to only dispatch the filter change
  const onYearChange = useCallback((year) => {
    dispatch(setFilters({ selectedYear: year }));
  }, [dispatch]);

  return (
    <>
      <Header
        selected="INCOME"
        title={isStoreManager ? t('report_Income') : state?.store?.name}
        store={state?.store || user?.store}
        showIncludedVAT={showIncludedVAT}
        setShowIncludedVAT={setShowIncludedVAT}
        onShowFieldsChange={handleShowFieldsChange}
      />
      <div className="contentContainer">
        <div className="incomeSortingContainer">
          <div className="sortingCenter">
            <div className="center-content">
              <div className="page-navigation">
                <button 
                  className="yearButton yearButton-left"
                  onClick={() => handlePageNavigation('prev')}
                  title={t('Previous page')}
                >
                  <LeftOutlined />
                </button>
                <div className="page-indicator">
                  <span>{t(isPreviewMode ? 'overview' : 'income')}</span>
                </div>
                <button 
                  className="yearButton yearButton-right"
                  onClick={() => handlePageNavigation('next')}
                  title={t('Next page')}
                >
                  <RightOutlined />
                </button>
              </div>

              <div className="date-selection">
                <YearPicker
                  selectedYear={selectedYear}
                  onChange={onYearChange}
                />
                <MonthsComponent
                  selectedMonth={selectedMonth}
                  selectedYear={selectedYear}
                />
              </div>

              <div></div>
            </div>
          </div>
        </div>
        <div className="scrollableContent">
          {!isPreviewMode ? (
            <>
              <div className="incomeContent">
                {isStoreManager && !user.storeId ? (
                  <h2>{t('no_store_assigned')}</h2>
                ) : (
                  <div className="incomeContentInner">
                    <div className='incomeWeekdaysHeader'>
                      <div className='weekDaysContainer'>
                        <text className='weekDays'>{t('monday')}</text>
                        <text className='weekDays'>{t('tuesday')}</text>
                        <text className='weekDays'>{t('wednesday')}</text>
                        <text className='weekDays'>{t('thursday')}</text>
                        <text className='weekDays'>{t('friday')}</text>
                        <text className='weekDays'>{t('saturday')}</text>
                        <text className='weekDays'>{t('sunday')}</text>
                      </div>
                    </div>
                    <div className='incomeCalendarContainer'>
                      {filteredData.map((item, weekIndex) => (
                        <div key={weekIndex} className='incomeCalendarRow'>
                          <div className='incomeCalendarRow__first'>
                            {showNetto && (
                              <text className='incomeCalendarRow__first--excluded'>
                                Netto
                              </text>
                            )}
                            {showBrutto && (
                              <text className='incomeCalendarRow__first--included'>
                                Brutto
                              </text>
                            )}
                          </div>
                          <div
                            className='incomeCalendarRow__data'
                            style={
                              weekIndex === 0
                                ? { justifyContent: 'flex-end' }
                                : {}
                            }
                          >
                            {item.data.map((inputField, index) => (
                              <IncomeCalendarCell
                                key={index}
                                inputField={inputField}
                                storeInfo={state?.store || user?.store}
                                onIncomeAdded={(res) =>
                                  onStoreIncomeAdded(weekIndex, index, res)
                                }
                                showBrutto={showBrutto}
                                showNetto={showNetto}
                                isPreviewMode={isPreviewMode}
                                weekIndex={weekIndex}
                                index={index}
                                isActive={activeCell.weekIndex === weekIndex && activeCell.index === index}
                                activeField={activeCell.field}
                                onTabToNextCell={(field) => handleTabToNextCell(weekIndex, index, field)}
                                onActivate={(field) => setActiveCell({ weekIndex, index, field })}
                              />
                            ))}
                          </div>
                          <div className='incomeCalendarRow__nameWeek'>
                            <text className='weekDays'>
                              {t('week')} {item.key}
                            </text>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <IncomeInsights
              storeIncome={storeIncome}
              selectedMonth={selectedMonth}
              selectedYear={selectedYear}
              showIncludedVAT={showIncludedVAT}
            />
          )}
        </div>
      </div>
    </>
  );
};
