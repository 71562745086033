import { getNeededCellData, toNumber, toServerDate } from './getCellData';

const getDateKey = (from, to) => {
  const fromDate = new Date(from);
  const toDate = new Date(to);
  const padNumber = (num) => String(num).padStart(2, '0');
  return `${padNumber(fromDate.getDate())}-${padNumber(fromDate.getMonth() + 1)}-${fromDate.getFullYear()}_${padNumber(toDate.getDate())}-${padNumber(toDate.getMonth() + 1)}-${toDate.getFullYear()}`;
};

export const getColumnTotal = (stores, column, columnsSettingsForDiff) => {
  if (column.isDiff) {
    return stores.reduce((total, store) => {
      const cellData = getNeededCellData(
        column.diffType === 'numbers' ? store.numberDiff : store.percentageDiff,
        columnsSettingsForDiff.type,
        columnsSettingsForDiff.tax,
      );

      return total + toNumber(cellData || 0);
    }, 0);
  }
  return stores.reduce((total, store) => {
    const dateKey = getDateKey(column.from, column.to);
    const storeData = store[dateKey];
    const cellData = getNeededCellData(
      storeData,
      column.type,
      column.tax,
    );

    return total + toNumber(cellData);
  }, 0);
};
