import dayjs from 'dayjs';
import generateUID from './utils/generateUID';

export const standartColumnProperties = {
  isVisible: true,
  tax: 'excluded',
  type: 'income',
};

export const diffColumns = [
  {
    isDiff: true,
    diffType: 'numbers',
    columnUID: generateUID(),
    isVisible: true,
  },
  {
    isDiff: true,
    diffType: 'percentage',
    columnUID: generateUID(),
    isVisible: true,
  },
];

export const generateDefaultColumns = () => [
  {
    from: dayjs().add(-1, 'year').startOf('month').format('YYYY-MM-DD'),
    to: dayjs().add(-1, 'year').endOf('month').format('YYYY-MM-DD'),
    columnUID: generateUID(),
    ...standartColumnProperties,
  },
  {
    from: dayjs().startOf('month').format('YYYY-MM-DD'),
    to: dayjs().add(-1, 'day').format('YYYY-MM-DD'),
    columnUID: generateUID(),
    isAccumulated: true,
    ...standartColumnProperties,
  },
  ...diffColumns,
];

export const generateDefaultTable = () => ({
  columns: generateDefaultColumns(),
  hiddenStores: [],
  hiddenMalls: [],
  minimizedMalls: [],
  sort: '',
  sortedByColumnUID: null,
  filters: {
    category: null
  },
});

export const defaultColumns = generateDefaultColumns();

export const defaultTables = {
  [generateUID()]: generateDefaultTable(),
};

export const SORT = ['', 'ASC', 'DESC'];
