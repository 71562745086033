import React from 'react';
import Tables from './components/Tables';

function IncomeTable() {
  return (
    <>
      <Tables />
    </>
  );
}

export default IncomeTable;
