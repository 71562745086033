import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import './styles.scss';

const PresetSection = ({ title, presets, onSelect }) => (
  <div className="preset-section">
    <div className="preset-section__title">{title}</div>
    <div className="preset-section__items">
      {presets.map((preset) => (
        <button
          key={preset.label}
          className="preset-button"
          onClick={() => onSelect(preset.from, preset.to)}
        >
          {preset.label}
          <span className="preset-button__range">{preset.range}</span>
        </button>
      ))}
    </div>
  </div>
);

const Presets = ({ onSelect }) => {
  const { t } = useTranslation();
  const now = dayjs();

  const dailyPresets = [
    {
      label: t('Today'),
      range: t('Current day'),
      from: now.startOf('day'),
      to: now.endOf('day')
    },
    {
      label: t('Yesterday'),
      range: t('Previous day'),
      from: now.subtract(1, 'day').startOf('day'),
      to: now.subtract(1, 'day').endOf('day')
    }
  ];

  const weeklyPresets = [
    {
      label: t('Last 7 days'),
      range: t('From 7 days ago to yesterday'),
      from: now.subtract(7, 'day').startOf('day'),
      to: now.subtract(1, 'day').endOf('day')
    },
    {
      label: t('Last 14 days'),
      range: t('From 14 days ago to yesterday'),
      from: now.subtract(14, 'day').startOf('day'),
      to: now.subtract(1, 'day').endOf('day')
    }
  ];

  const monthlyPresets = [
    {
      label: t('Current month'),
      range: t('From start of current month to yesterday'),
      from: now.startOf('month'),
      to: now.subtract(1, 'day').endOf('day')
    },
    {
      label: t('Previous month'),
      range: t('From start to end of previous month'),
      from: now.subtract(1, 'month').startOf('month'),
      to: now.subtract(1, 'month').endOf('month')
    }
  ];

  const yearlyPresets = [
    {
      label: t('Current year'),
      range: t('From start of current year to yesterday'),
      from: now.startOf('year'),
      to: now.subtract(1, 'day').endOf('day')
    },
    {
      label: t('Previous year'),
      range: t('From start to end of previous year'),
      from: now.subtract(1, 'year').startOf('year'),
      to: now.subtract(1, 'year').endOf('year')
    }
  ];

  const quarterlyPresets = [
    {
      label: t('Current quarter'),
      range: t('From start of current quarter to yesterday'),
      from: now.startOf('quarter'),
      to: now.subtract(1, 'day').endOf('day')
    },
    {
      label: t('Previous quarter'),
      range: t('From start to end of previous quarter'),
      from: now.subtract(1, 'quarter').startOf('quarter'),
      to: now.subtract(1, 'quarter').endOf('quarter')
    }
  ];

  return (
    <div className="presets">
      <div className="presets__grid">
        <div className="presets__column">
          <PresetSection title={t('Daily')} presets={dailyPresets} onSelect={onSelect} />
          <PresetSection title={t('Weekly')} presets={weeklyPresets} onSelect={onSelect} />
        </div>
        <div className="presets__column">
          <PresetSection title={t('Monthly')} presets={monthlyPresets} onSelect={onSelect} />
          <PresetSection title={t('Yearly')} presets={yearlyPresets} onSelect={onSelect} />
        </div>
        <div className="presets__column">
          <PresetSection title={t('Quarterly')} presets={quarterlyPresets} onSelect={onSelect} />
        </div>
      </div>
    </div>
  );
};

export default Presets; 