import * as React from 'react';
import { TbHanger } from 'react-icons/tb'; // Mote & Reise
import { BsBasket } from 'react-icons/bs'; // Mat og Drikke
import { AiOutlineHome } from 'react-icons/ai'; // Hus og Hjem
import { BiBriefcase } from 'react-icons/bi'; // Annen Virksomhet
import { BsHandbag } from 'react-icons/bs'; // Annen Detaljhandel
import { FiTool } from 'react-icons/fi'; // Tjenester
import { GiKnifeFork } from 'react-icons/gi'; // Servering
import { BiSearchAlt } from 'react-icons/bi'; // Spesialbutikker

// Fashion & Travel (Mote & Reise)
const FashionTravelIcon = ({ className, ...props }) => (
  <TbHanger className={className} {...props} />
);

// Food & Beverage (Mat og Drikke)
const FoodBeverageIcon = ({ className, ...props }) => (
  <BsBasket className={className} {...props} />
);

// House & Home (Hus og hjem)
const HouseHomeIcon = ({ className, ...props }) => (
  <AiOutlineHome className={className} {...props} />
);

// Other Business (Annen virksomhet)
const OtherBusinessIcon = ({ className, ...props }) => (
  <BiBriefcase className={className} {...props} />
);

// Other Retail (Annen detaljhandel)
const OtherRetailIcon = ({ className, ...props }) => (
  <BsHandbag className={className} {...props} />
);

// Services (Tjenester)
const ServicesIcon = ({ className, ...props }) => (
  <FiTool className={className} {...props} />
);

// Serving (Servering)
const ServingIcon = ({ className, ...props }) => (
  <GiKnifeFork className={className} {...props} />
);

// Special Stores (Spesialbutikker)
const SpecialStoresIcon = ({ className, ...props }) => (
  <BiSearchAlt className={className} {...props} />
);

export {
  FashionTravelIcon,
  FoodBeverageIcon,
  HouseHomeIcon,
  OtherBusinessIcon,
  OtherRetailIcon,
  ServicesIcon,
  ServingIcon,
  SpecialStoresIcon,
}; 