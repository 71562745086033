import { call, put, takeEvery } from 'redux-saga/effects';
import {
  setMalls,
  setStores,
  setStoresNewUser,
} from '../../Redux/mallsReducer/action';
import { ActionTypes } from './actionsTypes';
import axiosInstance from '../../Axios/axiosInstance';

export function* watcherGetMalls() {
  yield takeEvery(ActionTypes.GET_MALLS, workerGetMalls);
}

export function* workerGetMalls({ payload }) {
  const res = yield call(
    axiosInstance.get,
    `/admins/malls/?page=${payload.page || 1}&limit=${payload.page ? 10 : 100}${
      payload.order ? `&order=${payload.order}` : ''
    }`,
  );
  yield put(setMalls(res));
}

export function* watcherGetStores() {
  yield takeEvery(ActionTypes.GET_STORES, workerGetStores);
}

export function* workerGetStores({ payload }) {
  const res = yield call(
    axiosInstance.get,
    `/stores/malls/${payload.id}?page=${payload.page || 1}&limit=${
      payload.page ? 10 : 100
    }${payload.order ? `&order=${payload.order}` : ''}`,
  );
  yield put(setStores(res));
}

export function* watcherGetStoresNewUser() {
  yield takeEvery(ActionTypes.GET_STORES_NEW_USER, workerGetStoresNewUser);
}

export function* workerGetStoresNewUser({ payload }) {
  const res = yield call(
    axiosInstance.get,
    `/stores/malls/${payload.id}?page=${payload.page || 1}&limit=${
      payload.page ? 10 : 100
    }${payload.order ? `&order=${payload.order}` : ''}`,
  );
  yield put(setStoresNewUser(res));
}
