import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IncomeTableContext } from '../../../IncomeTableContext';
import { TableContext } from '../../Table/TableContext';
import { useMallState } from './hooks/useMallState';
import { getAllStores } from '../../../../../services/Saga/filters/actions';
import MallList from './components/MallList/MallList';
import CategoryFilter from './components/CategoryFilter/CategoryFilter';
import StoreList from './components/StoreList/StoreList';
import './styles.scss';

const RetailEditor = ({ 
  tableUID, 
  tableSettings, 
  toggleMallVisible, 
  toggleStoreVisible, 
  activeSection,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const { updateFilters, selectedMallId: contextSelectedMallId, setSelectedMallId } = useContext(IncomeTableContext);
  const { malls, visibleMalls } = useContext(TableContext);

  const { 
    activeMallIds,
    selectedMallId,
    handleMallClick,
    setSelectedMallId: setLocalSelectedMallId
  } = useMallState({ 
    malls, 
    hiddenMalls: tableSettings?.hiddenMalls || [], 
    tableUID, 
    toggleMallVisible, 
    activeSection
  });

  const allStores = useMemo(() => {
    if (selectedMallId) return null;
    return visibleMalls.flatMap(mall => mall.stores || []);
  }, [visibleMalls, selectedMallId]);

  const selectedMallData = useMemo(() => {
    return visibleMalls.find(mall => mall.id === contextSelectedMallId) || null;
  }, [visibleMalls, contextSelectedMallId]);

  useEffect(() => {
    dispatch(getAllStores());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      setSelectedMallId(null);
    };
  }, [setSelectedMallId]);

  useEffect(() => {
    if (contextSelectedMallId) {
      setLocalSelectedMallId(contextSelectedMallId);
    }
  }, [contextSelectedMallId, setLocalSelectedMallId]);

  return (
    <div className="retail-editor">
      <div className="retail-editor__unified-view">
        <div className="retail-editor__mall-section">
          <MallList
            malls={malls}
            activeMallIds={activeMallIds}
            selectedMallId={selectedMallId}
            handleMallClick={handleMallClick}
          />
        </div>
        
        <div className="retail-editor__right-section">
          <div className="retail-editor__stores-section">
            <StoreList
              malls={visibleMalls}
              selectedMallId={selectedMallId}
              tableUID={tableUID}
              tableSettings={tableSettings}
              toggleStoreVisible={toggleStoreVisible}
              activeCategory={tableSettings.filters?.storeGroupId}
              allStores={allStores}
              searchQuery={searchQuery}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RetailEditor; 