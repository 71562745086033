import packageJson from '../../package.json';
import { ENV_TYPE } from './constants';

const getVersion = () => {
  const envVersion = process.env.REACT_APP_VERSION;
  const pkgVersion = packageJson.version;
  
  if (ENV_TYPE !== 'production') {
    console.log('Environment:', ENV_TYPE);
    console.log('Environment Version:', envVersion);
    console.log('Package Version:', pkgVersion);
  }
  
  // For staging: Always use environment version which includes build number
  if (ENV_TYPE === 'staging') {
    return envVersion;
  }
  
  // For production: Use package.json version
  return pkgVersion;
};

export const APP_VERSION = getVersion();

export const getStoredVersion = () => {
  const stored = localStorage.getItem('app_version');
  if (ENV_TYPE !== 'production') {
    console.log('Stored Version:', stored);
  }
  return stored;
};

export const setStoredVersion = () => {
  if (ENV_TYPE !== 'production') {
    console.log('Setting Version:', APP_VERSION);
  }
  localStorage.setItem('app_version', APP_VERSION);
};

export const shouldClearStorage = () => {
  const storedVersion = getStoredVersion();
  const currentVersion = APP_VERSION;
  
  console.log('Version Check:', {
    stored: storedVersion,
    current: currentVersion,
    environment: ENV_TYPE,
    timestamp: new Date().toISOString()
  });
  
  if (!storedVersion) {
    setStoredVersion();
    return false;
  }
  
  // For staging: Compare full version including build number
  if (currentVersion.includes('-staging-build.')) {
    if (storedVersion !== currentVersion) {
      setStoredVersion();
      return true;
    }
  } 
  // For production: Compare only major.minor.patch
  else {
    const [storedBase] = storedVersion.split('-');  // Remove any staging suffix
    if (storedBase !== currentVersion) {
      setStoredVersion();
      return true;
    }
  }
  
  return false;
};

export const isNewVersion = () => {
  const storedVersion = getStoredVersion();
  if (!storedVersion) {
    setStoredVersion();
    return true;
  }
  return storedVersion !== APP_VERSION;
};

export const parseVersion = (version) => {
  const [major, minor, patch] = version.split('.').map(Number);
  return { major, minor, patch };
};

export const compareVersions = (v1, v2) => {
  const ver1 = parseVersion(v1);
  const ver2 = parseVersion(v2);

  if (ver1.major !== ver2.major) return ver1.major - ver2.major;
  if (ver1.minor !== ver2.minor) return ver1.minor - ver2.minor;
  return ver1.patch - ver2.patch;
}; 