import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, LabelList, Legend } from 'recharts';
import './styles.scss';
import { useSelector } from 'react-redux';

const formatCurrency = (value) => {
  if (!value) return '0';
  return Math.round(value).toLocaleString('no-NO');
};

const ChartTitle = ({ x, y, title, tooltip }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} fill="var(--text-secondary)" fontSize={13} fontWeight={500}>
        {title}
      </text>
      <foreignObject x={title.length * 8} y={-4} width={20} height={20}>
        <span className="info-icon" data-tooltip={tooltip}>?</span>
      </foreignObject>
    </g>
  );
};

const CustomTooltip = ({ active, payload, label, selectedYear, selectedMonth }) => {
  const { t } = useTranslation();
  if (active && payload && payload.length) {
    const currentYear = payload.find(p => p.dataKey === 'currentYear');
    const previousYear = payload.find(p => p.dataKey === 'previousYear');
    
    // Format the date using dayjs
    const currentDate = dayjs(`${selectedYear}-${selectedMonth?.number || 1}-${label}`);
    
    return (
      <div className="custom-tooltip">
        <p className="label">{currentDate.format('D. MMMM')}</p>
        {previousYear && (
          <p className="value">{t('previous_year', { year: selectedYear - 1 })}: {formatCurrency(previousYear.value)} {t('currency')}</p>
        )}
        {currentYear && (
          <p className="value included">{t('current_year', { year: selectedYear })}: {formatCurrency(currentYear.value)} {t('currency')}</p>
        )}
      </div>
    );
  }
  return null;
};

export const IncomeInsights = ({ storeIncome, selectedMonth, selectedYear, showIncludedVAT }) => {
  const { t } = useTranslation();
  const [mounted, setMounted] = useState(false);
  const yearToDateIncome = useSelector(state => state.storeIncomeReducer.yearToDateIncome);
  const previousYearIncome = useSelector(state => state.storeIncomeReducer.previousYearIncome);

  useEffect(() => {
    console.log('IncomeInsights - Data Check:', {
      storeIncome,
      yearToDateIncome,
      previousYearIncome,
      selectedMonth,
      selectedYear,
      timestamp: new Date().toISOString()
    });
  }, [storeIncome, yearToDateIncome, previousYearIncome, selectedMonth, selectedYear]);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  // Process year to date data
  const ytdData = useMemo(() => {
    console.log('Processing YTD data:', {
      hasYearToDateData: !!yearToDateIncome?.length,
      hasPreviousYearData: !!previousYearIncome?.length,
      yearToDateLength: yearToDateIncome?.length,
      previousYearLength: previousYearIncome?.length,
      selectedYear,
      showIncludedVAT,
      timestamp: new Date().toISOString()
    });

    const acc = {};

    // Initialize all months
    for (let month = 0; month < 12; month++) {
      const monthKey = dayjs().month(month).format('MMM');
      acc[monthKey] = {
        month: monthKey,
        currentYear: 0,
        previousYear: 0
      };
    }

    // Fill in current year data from yearToDateIncome
    yearToDateIncome?.forEach(income => {
      const incomeDate = dayjs(income.date);
      if (incomeDate.year() === selectedYear) {
        const monthKey = incomeDate.format('MMM');
        acc[monthKey].currentYear += showIncludedVAT ? (income.included || 0) : (income.excluded || 0);
      }
    });

    // Fill in previous year data
    previousYearIncome?.forEach(income => {
      const incomeDate = dayjs(income.date);
      if (incomeDate.year() === selectedYear - 1) {
        const monthKey = incomeDate.format('MMM');
        acc[monthKey].previousYear += showIncludedVAT ? (income.included || 0) : (income.excluded || 0);
      }
    });

    // Convert to array and sort by month order
    const monthOrder = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return monthOrder.map(month => ({
      month,
      currentYear: acc[month].currentYear,
      previousYear: acc[month].previousYear
    }));
  }, [yearToDateIncome, previousYearIncome, selectedYear, showIncludedVAT]);

  // Process monthly data
  const monthlyData = useMemo(() => {
    console.log('Processing monthly data:', {
      hasStoreIncome: !!storeIncome?.data,
      hasPreviousYearData: !!previousYearIncome?.length,
      storeIncomeLength: storeIncome?.data?.length,
      previousYearLength: previousYearIncome?.length,
      selectedMonth,
      selectedYear,
      showIncludedVAT,
      timestamp: new Date().toISOString()
    });

    if (!storeIncome?.data || !selectedMonth || !selectedYear) return [];
    
    const daysInMonth = dayjs(`${selectedYear}-${selectedMonth.number}`).daysInMonth();
    const acc = {};

    // Initialize all days
    for (let day = 1; day <= daysInMonth; day++) {
      acc[day] = {
        day: day.toString(),
        currentYear: 0,
        previousYear: 0
      };
    }

    // Process current year data
    storeIncome.data.forEach(income => {
      const incomeDate = dayjs(income.date);
      if (incomeDate.month() + 1 === selectedMonth.number && incomeDate.year() === selectedYear) {
        const dayKey = incomeDate.format('D');
        acc[dayKey].currentYear += showIncludedVAT ? (income.included || 0) : (income.excluded || 0);
      }
    });

    // Process previous year data
    previousYearIncome?.forEach(income => {
      const incomeDate = dayjs(income.date);
      if (incomeDate.month() + 1 === selectedMonth.number && incomeDate.year() === selectedYear - 1) {
        const dayKey = incomeDate.format('D');
        if (acc[dayKey]) {
          acc[dayKey].previousYear += showIncludedVAT ? (income.included || 0) : (income.excluded || 0);
        }
      }
    });

    return Object.values(acc);
  }, [storeIncome?.data, previousYearIncome, selectedMonth, selectedYear, showIncludedVAT]);

  // Process weekly data
  const weeklyData = useMemo(() => {
    console.log('Processing weekly data:', {
      hasStoreIncome: !!storeIncome?.data,
      hasPreviousYearData: !!previousYearIncome?.length,
      storeIncomeLength: storeIncome?.data?.length,
      previousYearLength: previousYearIncome?.length,
      selectedMonth,
      selectedYear,
      showIncludedVAT,
      timestamp: new Date().toISOString()
    });

    if (!storeIncome?.data || !selectedMonth || !selectedYear) return [];
    
    const acc = {};

    // Process current year data
    storeIncome.data
      .filter(income => {
        const incomeDate = dayjs(income.date);
        return incomeDate.month() + 1 === selectedMonth.number && incomeDate.year() === selectedYear;
      })
      .forEach(income => {
        const week = dayjs(income.date).week();
        if (!acc[week]) {
          acc[week] = {
            week: `${t('week')} ${week}`,
            currentYear: 0,
            previousYear: 0
          };
        }
        acc[week].currentYear += showIncludedVAT ? (income.included || 0) : (income.excluded || 0);
      });

    // Process previous year data
    previousYearIncome
      ?.filter(income => {
        const incomeDate = dayjs(income.date);
        return incomeDate.month() + 1 === selectedMonth.number && incomeDate.year() === selectedYear - 1;
      })
      .forEach(income => {
        const week = dayjs(income.date).week();
        if (!acc[week]) {
          acc[week] = {
            week: `${t('week')} ${week}`,
            currentYear: 0,
            previousYear: 0
          };
        }
        acc[week].previousYear += showIncludedVAT ? (income.included || 0) : (income.excluded || 0);
      });

    return Object.values(acc).sort((a, b) => {
      const weekA = parseInt(a.week.split(' ')[1]);
      const weekB = parseInt(b.week.split(' ')[1]);
      return weekA - weekB;
    });
  }, [storeIncome?.data, previousYearIncome, selectedMonth, selectedYear, showIncludedVAT, t]);

  // Process monthly summary
  const monthSummary = useMemo(() => {
    if (!storeIncome?.data || !selectedMonth || !selectedYear) return null;

    // Current year calculations
    const currentMonthTotal = monthlyData.reduce((sum, day) => sum + day.currentYear, 0);
    const currentDaysWithIncome = monthlyData.filter(day => day.currentYear > 0).length;
    const currentAvgDaily = currentMonthTotal / (currentDaysWithIncome || 1);
    const currentBestDay = monthlyData.reduce((best, day) => 
      day.currentYear > best.currentYear ? day : best, 
      { currentYear: 0 }
    );

    // Previous year calculations
    const previousMonthTotal = monthlyData.reduce((sum, day) => sum + day.previousYear, 0);
    const previousDaysWithIncome = monthlyData.filter(day => day.previousYear > 0).length;
    const previousAvgDaily = previousMonthTotal / (previousDaysWithIncome || 1);
    const previousBestDay = monthlyData.reduce((best, day) => 
      day.previousYear > best.previousYear ? day : best, 
      { previousYear: 0 }
    );

    // Calculate year-over-year changes
    const totalChange = currentMonthTotal - previousMonthTotal;
    const totalChangePercentage = previousMonthTotal ? (totalChange / previousMonthTotal) * 100 : 0;
    const avgDailyChange = currentAvgDaily - previousAvgDaily;
    const avgDailyChangePercentage = previousAvgDaily ? (avgDailyChange / previousAvgDaily) * 100 : 0;

    return {
      current: {
        total: currentMonthTotal.toLocaleString('no-NO'),
        avgDaily: Math.round(currentAvgDaily).toLocaleString('no-NO'),
        bestDay: currentBestDay.currentYear > 0 ? {
          date: `${currentBestDay.day}. ${selectedMonth.name}`,
          amount: currentBestDay.currentYear.toLocaleString('no-NO')
        } : null,
        daysWithIncome: currentDaysWithIncome
      },
      previous: {
        total: previousMonthTotal.toLocaleString('no-NO'),
        avgDaily: Math.round(previousAvgDaily).toLocaleString('no-NO'),
        bestDay: previousBestDay.previousYear > 0 ? {
          date: `${previousBestDay.day}. ${selectedMonth.name}`,
          amount: previousBestDay.previousYear.toLocaleString('no-NO')
        } : null,
        daysWithIncome: previousDaysWithIncome
      },
      changes: {
        total: {
          amount: totalChange.toLocaleString('no-NO'),
          percentage: totalChangePercentage.toFixed(1)
        },
        avgDaily: {
          amount: Math.round(avgDailyChange).toLocaleString('no-NO'),
          percentage: avgDailyChangePercentage.toFixed(1)
        }
      }
    };
  }, [monthlyData, storeIncome?.data, selectedMonth, selectedYear]);

  if (!mounted || !storeIncome?.data) return null;

  return (
    <div className="income-insights">
      <div className="insights-row summary-section">
        <div className="insight-section">
          <div className="summary-grid">
            <div className="summary-item">
              <label>
                {t('total_income')}
                <span className="info-icon" data-tooltip={t('income_description')}>?</span>
              </label>
              {monthSummary ? (
                <div className="values-container">
                  <div className="year-comparison">
                    <span className="year-label">{selectedYear - 1}:</span>
                    <span className="value">{monthSummary.previous.total} {t('currency')}</span>
                  </div>
                  <div className="year-comparison">
                    <span className="year-label">{selectedYear}:</span>
                    <span className="value included">{monthSummary.current.total} {t('currency')}</span>
                    <span className="change-indicator">
                      ({monthSummary.changes.total.percentage}% | {monthSummary.changes.total.amount} {t('currency')})
                    </span>
                  </div>
                </div>
              ) : (
                <span className="no-data">—</span>
              )}
            </div>
            <div className="summary-item">
              <label>
                {t('daily_average')}
                <span className="info-icon" data-tooltip={t('daily_average_description')}>?</span>
              </label>
              {monthSummary ? (
                <div className="values-container">
                  <div className="year-comparison">
                    <span className="year-label">{selectedYear - 1}:</span>
                    <span className="value">{monthSummary.previous.avgDaily} {t('currency')}</span>
                    <span className="days-info">({monthSummary.previous.daysWithIncome} {t('days')})</span>
                  </div>
                  <div className="year-comparison">
                    <span className="year-label">{selectedYear}:</span>
                    <span className="value included">{monthSummary.current.avgDaily} {t('currency')}</span>
                    <span className="days-info">({monthSummary.current.daysWithIncome} {t('days')})</span>
                    <span className="change-indicator">
                      ({monthSummary.changes.avgDaily.percentage}% | {monthSummary.changes.avgDaily.amount} {t('currency')})
                    </span>
                  </div>
                </div>
              ) : (
                <span className="no-data">—</span>
              )}
            </div>
            <div className="summary-item">
              <label>
                {t('best_day')}
                <span className="info-icon" data-tooltip={t('best_day_description')}>?</span>
              </label>
              {monthSummary ? (
                <div className="values-container">
                  <div className="year-comparison">
                    <span className="year-label">{selectedYear - 1}:</span>
                    {monthSummary.previous.bestDay ? (
                      <span className="value">{monthSummary.previous.bestDay.date}: {monthSummary.previous.bestDay.amount} {t('currency')}</span>
                    ) : (
                      <span className="no-data">—</span>
                    )}
                  </div>
                  <div className="year-comparison">
                    <span className="year-label">{selectedYear}:</span>
                    {monthSummary.current.bestDay ? (
                      <span className="value included">{monthSummary.current.bestDay.date}: {monthSummary.current.bestDay.amount} {t('currency')}</span>
                    ) : (
                      <span className="no-data">—</span>
                    )}
                  </div>
                </div>
              ) : (
                <span className="no-data">—</span>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="insights-row monthly-chart">
        <div className="insight-section">
          <div className="chart-container">
            <div className="chart-header">
              <span className="chart-title">{t('monthly_overview')}</span>
              <span className="info-icon" data-tooltip={t('monthly_overview_info')}>?</span>
            </div>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={monthlyData} margin={{ top: 30, right: 30, left: 0, bottom: 5 }}>
                <XAxis 
                  dataKey="day" 
                  tick={{ fill: 'var(--text-secondary)', fontSize: 11 }}
                  tickLine={{ stroke: 'var(--border-color)' }}
                  axisLine={{ stroke: 'var(--border-color)' }}
                  padding={{ left: 10, right: 10 }}
                  tickMargin={10}
                />
                <YAxis 
                  tick={{ fill: 'var(--text-secondary)', fontSize: 11 }}
                  tickLine={{ stroke: 'var(--border-color)' }}
                  axisLine={{ stroke: 'var(--border-color)' }}
                  tickFormatter={value => `${Math.round(value/1000)}k`}
                  tickMargin={10}
                />
                <Tooltip 
                  content={<CustomTooltip selectedYear={selectedYear} selectedMonth={selectedMonth} />}
                  cursor={{ fill: 'var(--bg-secondary)', opacity: 0.5 }}
                />
                <Bar 
                  dataKey="previousYear" 
                  name={t('previous_year', { year: selectedYear - 1 })}
                  fill="var(--chart-previous-year)" 
                  maxBarSize={15}
                  radius={[2, 2, 0, 0]}
                >
                  <LabelList 
                    dataKey="previousYear" 
                    position="top" 
                    formatter={(value, entry, index) => {
                      const maxValue = Math.max(...monthlyData.map(d => d.previousYear));
                      const minValue = Math.min(...monthlyData.filter(d => d.previousYear > 0).map(d => d.previousYear));
                      return (value === maxValue || value === minValue) ? `${Math.round(value/1000)}k` : '';
                    }}
                    fill="var(--chart-previous-year)"
                    fontSize={10}
                    offset={8}
                  />
                </Bar>
                <Bar 
                  dataKey="currentYear" 
                  name={t('current_year', { year: selectedYear })}
                  fill="var(--chart-current-year)" 
                  maxBarSize={15}
                  radius={[2, 2, 0, 0]}
                >
                  <LabelList 
                    dataKey="currentYear" 
                    position="top" 
                    formatter={(value, entry, index) => {
                      const maxValue = Math.max(...monthlyData.map(d => d.currentYear));
                      const minValue = Math.min(...monthlyData.filter(d => d.currentYear > 0).map(d => d.currentYear));
                      return (value === maxValue || value === minValue) ? `${Math.round(value/1000)}k` : '';
                    }}
                    fill="var(--chart-current-year)"
                    fontSize={10}
                    offset={8}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      <div className="insights-row weekly-chart">
        <div className="insight-section">
          <div className="chart-container">
            <div className="chart-header">
              <span className="chart-title">{t('weekly_overview')}</span>
              <span className="info-icon" data-tooltip={t('weekly_overview_info')}>?</span>
            </div>
            <ResponsiveContainer>
              <BarChart data={weeklyData} margin={{ top: 30, right: 30, left: 0, bottom: 5 }}>
                <XAxis 
                  dataKey="week" 
                  tick={{ fill: 'var(--text-secondary)', fontSize: 11 }}
                  height={35}
                  tickMargin={10}
                  interval={0}
                />
                <YAxis 
                  tick={{ fill: 'var(--text-secondary)', fontSize: 12 }}
                  tickFormatter={value => `${(value/1000)}k`}
                  width={60}
                  tickMargin={10}
                />
                <Tooltip 
                  content={<CustomTooltip selectedYear={selectedYear} />}
                  cursor={{ fill: 'var(--bg-secondary)', opacity: 0.5 }}
                />
                <Bar 
                  dataKey="previousYear" 
                  name={t('previous_year', { year: selectedYear - 1 })}
                  fill="var(--chart-previous-year)" 
                  maxBarSize={35}
                  radius={[3, 3, 0, 0]}
                >
                  <LabelList 
                    dataKey="previousYear" 
                    position="top" 
                    formatter={(value, entry, index) => {
                      const maxValue = Math.max(...weeklyData.map(d => d.previousYear));
                      const minValue = Math.min(...weeklyData.filter(d => d.previousYear > 0).map(d => d.previousYear));
                      return (value === maxValue || value === minValue) ? `${Math.round(value/1000)}k` : '';
                    }}
                    fill="var(--chart-previous-year)"
                    fontSize={12}
                    offset={10}
                  />
                </Bar>
                <Bar 
                  dataKey="currentYear" 
                  name={t('current_year', { year: selectedYear })}
                  fill="var(--chart-current-year)" 
                  maxBarSize={35}
                  radius={[3, 3, 0, 0]}
                >
                  <LabelList 
                    dataKey="currentYear" 
                    position="top" 
                    formatter={(value, entry, index) => {
                      const maxValue = Math.max(...weeklyData.map(d => d.currentYear));
                      const minValue = Math.min(...weeklyData.filter(d => d.currentYear > 0).map(d => d.currentYear));
                      return (value === maxValue || value === minValue) ? `${Math.round(value/1000)}k` : '';
                    }}
                    fill="var(--chart-current-year)"
                    fontSize={12}
                    offset={10}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      <div className="insights-row main-charts">
        <div className="insight-section">
          <div className="chart-container">
            <div className="chart-header">
              <span className="chart-title">{t('year_to_date_overview')}</span>
              <span className="info-icon" data-tooltip={t('year_to_date_info')}>?</span>
            </div>
            <ResponsiveContainer>
              <BarChart data={ytdData} margin={{ top: 30, right: 30, left: 0, bottom: 5 }}>
                <XAxis 
                  dataKey="month" 
                  tick={{ fill: 'var(--text-secondary)', fontSize: 11 }}
                  height={35}
                  tickMargin={10}
                  interval={0}
                />
                <YAxis 
                  tick={{ fill: 'var(--text-secondary)', fontSize: 12 }}
                  tickFormatter={value => `${(value/1000)}k`}
                  width={60}
                  tickMargin={10}
                />
                <Tooltip 
                  content={<CustomTooltip selectedYear={selectedYear} />}
                  cursor={{ fill: 'var(--bg-secondary)', opacity: 0.5 }}
                />
                <Bar 
                  dataKey="previousYear" 
                  name={t('previous_year', { year: selectedYear - 1 })}
                  fill="var(--chart-previous-year)" 
                  maxBarSize={35}
                  radius={[3, 3, 0, 0]}
                >
                  <LabelList 
                    dataKey="previousYear" 
                    position="top" 
                    formatter={(value, entry, index) => {
                      const maxValue = Math.max(...ytdData.map(d => d.previousYear));
                      const minValue = Math.min(...ytdData.filter(d => d.previousYear > 0).map(d => d.previousYear));
                      return (value === maxValue || value === minValue) ? `${Math.round(value/1000)}k` : '';
                    }}
                    fill="var(--chart-previous-year)"
                    fontSize={12}
                    offset={10}
                  />
                </Bar>
                <Bar 
                  dataKey="currentYear" 
                  name={t('current_year', { year: selectedYear })}
                  fill="var(--chart-current-year)" 
                  maxBarSize={35}
                  radius={[3, 3, 0, 0]}
                >
                  <LabelList 
                    dataKey="currentYear" 
                    position="top" 
                    formatter={(value, entry, index) => {
                      const maxValue = Math.max(...ytdData.map(d => d.currentYear));
                      const minValue = Math.min(...ytdData.filter(d => d.currentYear > 0).map(d => d.currentYear));
                      return (value === maxValue || value === minValue) ? `${Math.round(value/1000)}k` : '';
                    }}
                    fill="var(--chart-current-year)"
                    fontSize={12}
                    offset={10}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncomeInsights; 