import { cloneDeep } from 'lodash';
import { calculateMoneyDiff, calculatePercentageDiff } from './getDiff';

const fillTable = (tablesSettings, totalIncomeAmount) => {
  if (!tablesSettings || !totalIncomeAmount) {
    console.log('Missing data in fillTable:', {
      hasTableSettings: !!tablesSettings,
      hasTotalIncomeAmount: !!totalIncomeAmount,
      timestamp: new Date().toISOString()
    });
    return tablesSettings;
  }
  
  console.log('Starting fillTable with:', {
    tableCount: Object.keys(tablesSettings).length,
    incomeDataCount: Object.keys(totalIncomeAmount).length,
    timestamp: new Date().toISOString()
  });

  const copieTotalIncomeAmount = cloneDeep(totalIncomeAmount);
  const copiedTablesSettings = cloneDeep(tablesSettings);

  Object.keys(copiedTablesSettings).forEach((tableUID) => {
    if (!copieTotalIncomeAmount[tableUID]) {
      console.log('No income data for table:', {
        tableUID,
        timestamp: new Date().toISOString()
      });
      return;
    }

    console.log('Processing table:', {
      tableUID,
      columnCount: copiedTablesSettings[tableUID].columns.length,
      visibleColumns: copiedTablesSettings[tableUID].columns.filter(col => col.isVisible).length,
      nonDiffColumns: copiedTablesSettings[tableUID].columns.filter(col => !col.isDiff).length,
      timestamp: new Date().toISOString()
    });

    copiedTablesSettings[tableUID] = {
      ...copiedTablesSettings[tableUID],
      malls:
        copiedTablesSettings[tableUID].columns
          .filter((col) => col.isVisible)
          .filter((col) => !col.isDiff).length === 2
          ? copieTotalIncomeAmount[tableUID].map((mall) => {
              const selectedCategory = copiedTablesSettings[tableUID].filters?.category;
              const filteredStores = mall.stores?.filter(store => 
                !selectedCategory || store.category === selectedCategory
              ) || [];
              
              console.log('Processing mall:', {
                mallId: mall.id,
                mallName: mall.name,
                storeCount: filteredStores.length,
                selectedCategory,
                timestamp: new Date().toISOString()
              });

              return {
                ...mall,
                stores: filteredStores.map((store) => {
                  return {
                    ...store,
                    numberDiff: calculateMoneyDiff(store),
                    percentageDiff: calculatePercentageDiff(store),
                  };
                }),
              };
            })
          : copieTotalIncomeAmount[tableUID],
    };
  });

  console.log('Completed fillTable:', {
    processedTableCount: Object.keys(copiedTablesSettings).length,
    timestamp: new Date().toISOString()
  });

  return copiedTablesSettings;
};

export default fillTable;
