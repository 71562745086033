import { ActionTypes } from '../../Saga/storeIncome/actionsTypes';

export const setStoreIncome = (payload) => ({
  type: ActionTypes.SET_STORE_INCOME,
  payload,
});

export const setYearToDateIncome = (payload) => ({
  type: ActionTypes.SET_YEAR_TO_DATE_INCOME,
  payload,
});

export const setPreviousYearIncome = (payload) => ({
  type: ActionTypes.SET_PREVIOUS_YEAR_INCOME,
  payload,
});

export const setError = (payload) => ({
  type: ActionTypes.SET_ERROR,
  payload,
});
