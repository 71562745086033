import { useState, useEffect } from 'react';

export function useMallState({ malls, hiddenMalls, tableUID, toggleMallVisible, activeSection }) {
  const [selectedMallId, setSelectedMallId] = useState(null);

  // Initialize selected mall
  useEffect(() => {
    if (malls.length > 0) {
      // Only update selection if current selection is invalid or missing
      if (!selectedMallId || !malls.find(m => m.id === selectedMallId)) {
        // Select the first visible mall
        const firstVisibleMall = malls.find(mall => !hiddenMalls.includes(mall.id));
        setSelectedMallId(firstVisibleMall?.id || null);
      }
    }
  }, [malls, hiddenMalls, selectedMallId]);

  const handleMallClick = (mallId) => {
    const isHidden = hiddenMalls.includes(mallId);
    const isSelected = selectedMallId === mallId;

    if (isSelected) {
      // If clicking the selected mall, hide it and select another visible mall
      toggleMallVisible(tableUID, mallId);
      const nextMall = malls.find(m => !hiddenMalls.includes(m.id) && m.id !== mallId);
      setSelectedMallId(nextMall?.id || null);
    } else if (isHidden) {
      // If clicking a hidden mall, show it and select it
      toggleMallVisible(tableUID, mallId);
      setSelectedMallId(mallId);
    } else {
      // If clicking a visible, unselected mall, just select it
      setSelectedMallId(mallId);
    }
  };

  // Calculate active mall IDs from hiddenMalls
  const activeMallIds = new Set(
    malls
      .filter(mall => !hiddenMalls.includes(mall.id))
      .map(mall => mall.id)
  );

  return {
    activeMallIds,
    selectedMallId,
    handleMallClick,
    setSelectedMallId
  };
} 