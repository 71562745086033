import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useOutsideClick from '../../utils/hooks/useOutsideClick';
import { ROLES } from '../../pages/AdminPanel/utils/formatForTables';
import ROUTES from '../../routes/route';
import { logOutRed as logOut } from '../../services/Redux/loginReducer/action';
import { setTheme as setUserTheme } from '../../services/Redux/loginReducer/action';
import { getAllMalls } from '../../services/Saga/filters/actions';
import { getStores } from '../../services/Saga/getMallsList/actions';
import { IncomeTableContext } from '../IncomeTable/IncomeTableContext';
import './styles/index.scss';
import Add from '../../assets/icons/Add';
import ExportNew from '../../assets/icons/ExportNew';
import exportTable from '../IncomeTable/utils/exportTable';
import ExportProgress from '../IncomeTable/components/ExportProgress';
import ArrowDown from '../../assets/icons/ArrowDown';
import ArrowUp from '../../assets/icons/ArrowUp';
import { setSelectedMall, setFilters, setSelectedStore } from '../../services/Redux/reportingListReducer/action';
import dayjs from 'dayjs';

const DropdownButton = ({ label, isOpen, onClick, className = '' }) => (
  <button 
    className={`nav-button ${isOpen ? 'open' : ''} ${className}`}
    onClick={onClick}
  >
    {label}
  </button>
);

export const Header = ({ 
  title, 
  selected, 
  onToggleInactiveStores, 
  showInactiveStores,
  store,
  showIncludedVAT,
  setShowIncludedVAT,
  isPreviewMode,
  onShowFieldsChange
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.loginReducer.user);
  const allMalls = useSelector((state) => state.filtersReducer.allMalls?.data || state.filtersReducer.allMalls || []);
  const stores = useSelector((state) => state.mallsReducer.stores?.data || []);
  const lastViewedStore = useSelector(state => state.reportingListReducer.lastViewedStore);
  const [progress, setProgress] = useState(0);
  const [showLanguageOptions, setShowLanguageOptions] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [storeInfoDropdownOpen, setStoreInfoDropdownOpen] = useState(false);
  const [mallDropdownOpen, setMallDropdownOpen] = useState(false);
  const [storeDropdownOpen, setStoreDropdownOpen] = useState(false);
  const [showFieldsOpen, setShowFieldsOpen] = useState(false);

  // Initialize refs
  const accountDropdownRef = useRef(null);
  const mallDropdownRef = useRef(null);
  const storeDropdownRef = useRef(null);
  const showFieldsRef = useRef(null);

  // Use the hook for each dropdown
  const accountRef = useOutsideClick(() => {
    setDropdownOpen(false);
    setShowLanguageOptions(false);
  }, accountDropdownRef);

  const mallRef = useOutsideClick(() => {
    setMallDropdownOpen(false);
  }, mallDropdownRef);

  const storeRef = useOutsideClick(() => {
    setStoreDropdownOpen(false);
  }, storeDropdownRef);

  const fieldsRef = useOutsideClick(() => {
    setShowFieldsOpen(false);
  }, showFieldsRef);

  const {
    selectedTable,
    tablesSettings,
    addTable,
    isEditorOpen,
  } = useContext(IncomeTableContext);

  const isStoreManager = user?.roles?.[0] === ROLES.STORE_MANAGER;

  const languageOptions = [
    { label: 'English', value: 'en' },
    { label: 'Norsk', value: 'nor' }
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [vatDropdownOpen, setVatDropdownOpen] = useState(false);
  const [showBrutto, setShowBrutto] = useState(() => {
    const saved = localStorage.getItem('showBrutto');
    return saved !== null ? JSON.parse(saved) : true;
  });
  const [showNetto, setShowNetto] = useState(() => {
    const saved = localStorage.getItem('showNetto');
    return saved !== null ? JSON.parse(saved) : true;
  });

  // Effect to save changes to localStorage
  useEffect(() => {
    localStorage.setItem('showBrutto', JSON.stringify(showBrutto));
    localStorage.setItem('showNetto', JSON.stringify(showNetto));
  }, [showBrutto, showNetto]);

  // Initial effect to notify parent of default values
  useEffect(() => {
    onShowFieldsChange?.({ showBrutto, showNetto });
  }, []);

  const selectedMall = useSelector(state => state.reportingListReducer.selectedMall);
  const selectedStore = useSelector(state => state.reportingListReducer.selectedStore);

  // Get current month and year from location state or use current date
  const currentDate = dayjs();
  const { selectedMonth, selectedYear } = location.state || {
    selectedMonth: { value: currentDate.month(), label: currentDate.format('MMM') },
    selectedYear: currentDate.year()
  };

  useEffect(() => {
    // Set initial theme from user settings
    if (user?.theme) {
      document.documentElement.setAttribute('data-theme', user.theme);
    }
  }, [user]);

  const handleLanguageChange = (value) => {
    setSelectedLanguage(value);
    i18n.changeLanguage(value);
    localStorage.setItem('language', value);
    handleCloseDropdown();
  };

  const handleFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  const handleLogout = () => {
    dispatch(logOut(navigate));
  };

  const handleCloseDropdown = () => {
    setDropdownOpen(false);
    setShowLanguageOptions(false);
  };

  const onTitlePress = (key) => {
    navigate(key);
  };

  const handleThemeToggle = () => {
    const newTheme = user?.theme === 'dark' ? 'light' : 'dark';
    dispatch(setUserTheme(newTheme));
  };

  const handleAdd = () => {
    if (isAddDisabled) return;
    addTable();
  };

  const handleExport = async () => {
    if (!tablesSettings || progress !== 0 || selectedTable || isStoreManager) return;
    setProgress(1);
    exportTable(tablesSettings, setProgress).finally(() => setProgress(0));
  };

  const isAddDisabled = selectedTable || 
    Object.keys(tablesSettings || {}).length >= (isStoreManager ? 3 : 10);

  const handleAccountButtonClick = (e) => {
    e.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  const handleShowFieldsButtonClick = (e) => {
    e.stopPropagation();
    setShowFieldsOpen(!showFieldsOpen);
  };

  const handleMallButtonClick = (e) => {
    e.stopPropagation();
    setMallDropdownOpen(!mallDropdownOpen);
  };

  const handleStoreButtonClick = (e) => {
    e.stopPropagation();
    setStoreDropdownOpen(!storeDropdownOpen);
  };

  // Initial load of malls
  useEffect(() => {
    dispatch(getAllMalls());
    console.log('Header - Loading malls');
  }, [dispatch]);

  // Add logging for malls data
  useEffect(() => {
    console.log('Header - Mall Dropdown State:', {
      allMalls,
      selectedMall,
      isDropdownOpen: mallDropdownOpen
    });
  }, [allMalls, selectedMall, mallDropdownOpen]);

  // Load stores when mall changes
  useEffect(() => {
    if (selectedMall?.id) {
      dispatch(getStores({ id: selectedMall.id }));
      console.log('Header - Loading stores for mall:', selectedMall.name);
    }
  }, [selectedMall?.id, dispatch]);

  // Add logging for stores data
  useEffect(() => {
    console.log('Header - Store Dropdown State:', {
      stores,
      selectedStore,
      isDropdownOpen: storeDropdownOpen,
      filteredStores: stores.filter(store => store.mallId === selectedMall?.id)
    });
  }, [stores, selectedStore, storeDropdownOpen, selectedMall]);

  // Handle store/mall persistence across navigation
  useEffect(() => {
    // If we don't have a selected mall/store, try to restore from last viewed
    if (!selectedMall && !selectedStore) {
      if (lastViewedStore?.mallName) {
        const mall = allMalls.find(m => m.name === lastViewedStore.mallName);
        if (mall) {
          console.log('Header - Restoring last viewed mall:', mall.name);
          dispatch(setSelectedMall(mall));
          // Load stores for this mall
          dispatch(getStores({ id: mall.id }));
        }
      }
    }
  }, [lastViewedStore, allMalls, selectedMall, selectedStore, dispatch]);

  // When stores are loaded, try to restore the last viewed store
  useEffect(() => {
    if (stores.length > 0 && lastViewedStore?.name && !selectedStore) {
      const storeToSelect = stores.find(s => 
        s.name === lastViewedStore.name && 
        s.mallId === selectedMall?.id
      );
      if (storeToSelect) {
        console.log('Header - Restoring last viewed store:', storeToSelect.name);
        dispatch(setSelectedStore(storeToSelect));
      }
    }
  }, [stores, lastViewedStore, selectedMall, selectedStore, dispatch]);

  const handleMallSelect = (mallName) => {
    const mall = allMalls.find(m => m.name === mallName);
    if (mall) {
      console.log('Header - Mall selected:', {
        name: mall.name,
        id: mall.id,
        previousMall: selectedMall?.name,
        selected,
        selectedMonth,
        selectedYear
      });
      
      // Update both the selected mall and the filters
      dispatch(setSelectedMall(mall));
      dispatch(setFilters({ selectedMallId: mall.id }));
      dispatch(setSelectedStore(null));

      // If we're on the income page, navigate back to reporting list
      if (selected === 'INCOME') {
        navigate(ROUTES.REPORTING_LIST, {
          state: {
            selectedMonth,
            selectedYear
          }
        });
      }
    }
    setMallDropdownOpen(false);
  };

  const handleStoreSelect = (storeName) => {
    const selectedStoreData = stores.find(s => s.name === storeName && s.mallId === selectedMall?.id);
    if (selectedStoreData) {
      console.log('Header - Store selected:', {
        name: selectedStoreData.name,
        id: selectedStoreData.id,
        mallId: selectedStoreData.mallId,
        previousStore: selectedStore?.name,
        selectedMonth,
        selectedYear
      });
      dispatch(setSelectedStore(selectedStoreData));
      
      // Navigate to income page with the selected store
      navigate(ROUTES.REPORT_INCOME, {
        state: {
          store: {
            id: selectedStoreData.id,
            name: selectedStoreData.name,
            mallName: selectedMall?.name,
            mallId: selectedMall?.id,
            tax: selectedStoreData.tax
          },
          selectedMonth,
          selectedYear
        }
      });
    }
    setStoreDropdownOpen(false);
  };

  return (
    <header className="main-header">
      <ExportProgress progress={progress} />
      <div className="header-wrapper">
        <div className="header-left">
          <nav className="header-nav">
            {user?.roles[0] === ROLES.STORE_MANAGER ? (
              <button 
                className={`nav-button ${selected === 'INCOME' ? 'selected' : ''}`}
                onClick={() => onTitlePress(ROUTES.REPORT_INCOME)}
              >
                {t('report_Income')}
              </button>
            ) : (
              <>
                <button 
                  className={`nav-button ${selected === 'HOME' ? 'selected' : ''}`}
                  onClick={() => onTitlePress(ROUTES.DASHBOARD.HOME)}
                >
                  {t('dashboard')}
                </button>
                <button 
                  className={`nav-button ${selected === 'LIST' || selected === 'INCOME' ? 'selected' : ''}`}
                  onClick={() => onTitlePress(ROUTES.REPORTING_LIST)}
                >
                  {t('reporting_List')}
                </button>

                {user?.roles[0] === ROLES.ADMIN && (
                  <button 
                    className={`nav-button ${selected === 'ADMIN' ? 'selected' : ''}`}
                    onClick={() => onTitlePress(ROUTES.ADMIN.MALLS.LIST)}
                  >
                    {t('admin_Panel')}
                  </button>
                )}
              </>
            )}
          </nav>
          {title && selected !== 'INCOME' && <h1 className="header-title">{title}</h1>}
        </div>

        <div className="header-controls">
          {/* Mall and Store dropdowns - only for non-store managers */}
          {(selected === 'INCOME' || selected === 'LIST') && !isStoreManager && (
            <>
              <div ref={mallDropdownRef} className="user-dropdown">
                <button 
                  className={`nav-button ${mallDropdownOpen ? 'open' : ''}`}
                  onClick={handleMallButtonClick}
                >
                  {selectedMall?.name || t('Select Mall')}
                </button>
                {mallDropdownOpen && (
                  <div className="dropdown-content">
                    {(allMalls?.data || allMalls || []).map(mall => (
                      <div 
                        key={mall.id} 
                        className={`dropdown-item ${selectedMall?.id === mall.id ? 'selected' : ''}`}
                        onClick={() => handleMallSelect(mall.name)}
                      >
                        <span className="value">{mall.name}</span>
                        {selectedMall?.id === mall.id && <span>✓</span>}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {selectedMall && (
                <div ref={storeDropdownRef} className="user-dropdown">
                  <button 
                    className={`nav-button ${storeDropdownOpen ? 'open' : ''}`}
                    onClick={handleStoreButtonClick}
                  >
                    {selectedStore?.name || t('Select Store')}
                  </button>
                  {storeDropdownOpen && (
                    <div className="dropdown-content">
                      {stores
                        .filter(store => store.mallId === selectedMall.id)
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(store => (
                          <div 
                            key={store.id} 
                            className={`dropdown-item ${selectedStore?.id === store.id ? 'selected' : ''}`}
                            onClick={() => handleStoreSelect(store.name)}
                          >
                            <span className="value">{store.name}</span>
                            {selectedStore?.id === store.id && <span>✓</span>}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              )}
            </>
          )}

          {/* Add Table button - show on HOME page */}
          {selected === 'HOME' && (
            <button 
              className={`nav-button ${isAddDisabled ? 'disabled' : ''}`}
              onClick={handleAdd}
              disabled={isAddDisabled}
            >
              {t('add_table')}
            </button>
          )}

          {/* Brutto/Netto options - for all users */}
          {selected === 'INCOME' && !isPreviewMode && (
            <div ref={showFieldsRef} className="user-dropdown">
              <button
                className={`nav-button ${showFieldsOpen ? 'open' : ''}`}
                onClick={handleShowFieldsButtonClick}
              >
                {showBrutto && showNetto ? 'Moms' : showBrutto ? 'Brutto' : 'Netto'}
              </button>
              {showFieldsOpen && (
                <div className="dropdown-content">
                  <div 
                    className="dropdown-item"
                    onClick={() => {
                      const newShowBrutto = !showBrutto;
                      setShowBrutto(newShowBrutto);
                      const newShowNetto = !newShowBrutto ? true : showNetto;
                      setShowNetto(newShowNetto);
                      onShowFieldsChange?.({ showBrutto: newShowBrutto, showNetto: newShowNetto });
                    }}
                  >
                    <span>Brutto</span>
                    {showBrutto && <span>✓</span>}
                  </div>
                  <div 
                    className="dropdown-item"
                    onClick={() => {
                      const newShowNetto = !showNetto;
                      setShowNetto(newShowNetto);
                      const newShowBrutto = !newShowNetto ? true : showBrutto;
                      setShowBrutto(newShowBrutto);
                      onShowFieldsChange?.({ showBrutto: newShowBrutto, showNetto: newShowNetto });
                    }}
                  >
                    <span>Netto</span>
                    {showNetto && <span>✓</span>}
                  </div>
                </div>
              )}
            </div>
          )}

          {selected === 'INCOME' && isPreviewMode && (
            <button 
              className="nav-button"
              onClick={() => setShowIncludedVAT(!showIncludedVAT)}
            >
              {showIncludedVAT ? t('brutto') : t('netto')}
            </button>
          )}
        </div>

        <div className="header-actions">
          {selected === 'HOME' && (
            <>
              <button 
                className={`nav-button ${progress !== 0 || selectedTable || isStoreManager ? 'disabled' : ''}`}
                onClick={handleExport}
                disabled={progress !== 0 || selectedTable || isStoreManager}
              >
                {t('adminTable.controller.export')}
              </button>
              {progress !== 0 && <ExportProgress progress={progress} />}
            </>
          )}

          <button 
            className="nav-button"
            onClick={handleFullScreen}
          >
            {t('open_fullscreen')}
          </button>

          <div ref={accountDropdownRef} className="user-dropdown">
            <button 
              className={`nav-button ${dropdownOpen ? 'open' : ''}`}
              onClick={handleAccountButtonClick}
            >
              {t('konto')}
            </button>
            
            {dropdownOpen && (
              <div className="dropdown-content">
                <div className="dropdown-item">
                  <span>{user?.email}</span>
                </div>
                
                <div 
                  className="dropdown-item"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowLanguageOptions(!showLanguageOptions);
                  }}
                >
                  <span>{t('choose_language')}</span>
                </div>
                
                {showLanguageOptions && languageOptions.map(option => (
                  <div 
                    key={option.value}
                    className={`dropdown-item language-option ${option.value === selectedLanguage ? 'selected' : ''}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleLanguageChange(option.value);
                    }}
                  >
                    {option.label}
                  </div>
                ))}

                <div 
                  className="dropdown-item"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleThemeToggle();
                  }}
                >
                  <span>{user?.theme === 'dark' ? t('dark_theme') : t('light_theme')}</span>
                </div>
                
                <div 
                  className="dropdown-item"
                  onClick={() => {
                    handleLogout();
                    setDropdownOpen(false);
                  }}
                >
                  {t('log_Out')}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};
