import React, { useCallback, useEffect, useState, useRef } from 'react';
import './styles.scss';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import addSpaces from '../../../../utils/addSpaces';
import axiosInstance from '../../../../services/Axios/axiosInstance';

const transformDate = (date) => {
  if (date) return dayjs(date).format('D');
};

const errorStyle = {
  borderWidth: '1px',
  borderColor: 'red',
};

function IncomeCalendarCell({ 
  inputField, 
  storeInfo, 
  onIncomeAdded, 
  showBrutto = true, 
  showNetto = false, 
  isPreviewMode = false, 
  showIncludedVAT = true,
  isActive,
  activeField,
  onTabToNextCell,
  onActivate,
  weekIndex,
  index
}) {
  const isFixed = storeInfo?.tax === 'Fixed';
  const excluded = useRef();
  const included = useRef();

  const [values, setValues] = useState({
    included: {
      error: false,
      value: '',
    },
    excluded: {
      error: false,
      value: '',
    },
  });

  // Log component mount and unmount
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      // Only log first day of each week to reduce noise
      if (index === 0) {
        // Only log if this is a significant week (first or last week of visible data)
        const isFirstWeek = weekIndex === 0;
        const isLastWeek = weekIndex === 4; // Calendar typically shows ~5 weeks
        
        if (isFirstWeek || isLastWeek) {
          const logData = {
            weekIndex,
            date: inputField?.date,
            position: isFirstWeek ? 'First Week' : 'Last Week',
            weekNumber: inputField?.week,
            isPreviewMode
          };
          
          // Only log if we have valid data
          if (inputField?.date) {
            console.log('IncomeCalendarCell - Key Week', logData);
          }
        }
      }
    }
  }, [inputField?.date]);

  // Log only significant state changes
  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && isActive) {
      console.log('IncomeCalendarCell - Active Cell Update', {
        weekIndex,
        index,
        date: inputField?.date,
        field: activeField
      });
    }
  }, [isActive, activeField]);

  // Separate effect for error handling
  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && (values.included.error || values.excluded.error)) {
      console.log('IncomeCalendarCell - Validation Error', {
        weekIndex,
        index,
        date: inputField?.date,
        includedError: values.included.error,
        excludedError: values.excluded.error
      });
    }
  }, [values.included.error, values.excluded.error]);

  useEffect(() => {
    setValues({
      included: {
        error: false,
        value: inputField.id ? inputField.included : '',
      },
      excluded: {
        error: false,
        value: inputField.id ? inputField.excluded : '',
      },
    });
  }, [inputField]);

  const addIncome = (payload) => {
    const body = {
      storeId: payload.storeId,
      included: payload.included,
      excluded: payload.excluded,
      date: dayjs(payload.date).format('YYYY-MM-DD'),
    };
    const putBody = {
      included: payload.included,
      excluded: payload.excluded,
    };
    return payload.inputFieldId
      ? axiosInstance.put(`/incomes/${payload.inputFieldId}`, putBody)
      : axiosInstance.post(`/incomes`, body);
  };

  const changeHandler = async (values) => {
    const valuesAreZeros =
      +values.included.value === 0 && +values.excluded.value === 0;

    if (+values.included.value < +values.excluded.value && !valuesAreZeros) {
      setValues((p) => ({
        included: {
          error: true,
          value: values.included.value,
        },
        excluded: {
          error: true,
          value: p.excluded.value,
        },
      }));
    } else {
      const res = await addIncome({
        storeId: storeInfo?.id,
        included: values.included.value === '' ? null : +values.included.value,
        excluded: values.excluded.value === '' ? null : +values.excluded.value,
        date: inputField?.date,
        inputFieldId: inputField?.id,
      });
      onIncomeAdded(
        res?.id
          ? res
          : {
              storeId: storeInfo?.id,
              included:
                values.included.value === '' ? null : +values.included.value,
              excluded:
                values.excluded.value === '' ? null : +values.excluded.value,
              date: inputField?.date,
              id: inputField?.id,
            },
      );
    }
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 1000), [
    inputField?.id,
    inputField?.date,
    inputField?.storeId,
  ]);

  const onIncludedChange = (event) => {
    const value = event.target.value.replaceAll(' ', '').replace(/[^\d.]/g, '');

    const newValues = {
      included: { error: false, value },
      excluded: isFixed
        ? { error: false, value: value === '' ? '' : value / 1.25 }
        : values.excluded,
    };
    setValues(newValues);
    debouncedChangeHandler(newValues);
  };

  const onExcludedChange = (event) => {
    if (isFixed) return;
    const value = event.target.value.replaceAll(' ', '').replace(/[^\d.]/g, '');
    const newValues = {
      ...values,
      excluded: { error: false, value },
    };
    setValues(newValues);
    debouncedChangeHandler(newValues);
  };

  const isAfterToday = dayjs(inputField.date).isAfter(dayjs());
  const isSunday = dayjs(inputField?.date).day() === 0;

  const isDisabled =
      !isSunday && (
          (storeInfo?.endDate
              ? dayjs(storeInfo.endDate).add(1, 'day').isBefore(dayjs(inputField?.date))
              : false) ||
          (storeInfo?.startDate
              ? dayjs(storeInfo.startDate).isAfter(dayjs(inputField?.date))
              : false) ||
          inputField.isLocked
      );

  // Focus the active field when it changes
  useEffect(() => {
    if (isActive) {
      if (activeField === 'excluded') {
        excluded.current?.focus();
      } else if (activeField === 'included') {
        included.current?.focus();
      }
    }
  }, [isActive, activeField]);

  const handleKeyDown = (e, type) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      onTabToNextCell(type);
    }
  };

  return (
    <div
      className='IncomeCalendarCell'
      style={{ gap: 12, opacity: isDisabled ? 0.6 : 1 }}
    >
      <div className='IncomeCalendarCell__day'>{transformDate(inputField?.date)}</div>

      {!isPreviewMode ? (
        <>
          {showNetto && (
            <>
              <div
                className='editableCalendarInput excluded'
                style={{ display: !isActive || activeField !== 'excluded' ? 'none' : 'flex' }}
              >
                <input
                  ref={excluded}
                  value={addSpaces(values.excluded.value, { emptyValue: '' })}
                  onChange={onExcludedChange}
                  onKeyDown={(e) => handleKeyDown(e, 'excluded')}
                  disabled={isFixed || isDisabled}
                  style={values.excluded.error ? errorStyle : {}}
                />
              </div>
              <text
                className='sum'
                onClick={() => onActivate('excluded')}
                style={{ display: isActive && activeField === 'excluded' ? 'none' : 'block' }}
              >
                {inputField.excluded !== null ? addSpaces(inputField.excluded) : '-'}
              </text>
            </>
          )}
          {showBrutto && (
            <>
              <div
                className='editableCalendarInput'
                style={{ display: !isActive || activeField !== 'included' ? 'none' : 'flex' }}
              >
                <input
                  disabled={isDisabled}
                  ref={included}
                  value={addSpaces(values.included.value, { emptyValue: '' })}
                  onChange={onIncludedChange}
                  onKeyDown={(e) => handleKeyDown(e, 'included')}
                  style={values.included.error ? errorStyle : {}}
                />
              </div>
              <text
                className='sum included'
                onClick={() => onActivate('included')}
                style={{ display: isActive && activeField === 'included' ? 'none' : 'block' }}
              >
                {inputField.included !== null ? addSpaces(inputField.included) : '-'}
              </text>
            </>
          )}
        </>
      ) : (
        <text
          className={`sum ${showIncludedVAT ? 'included' : ''}`}
          style={{ display: isActive ? 'none' : 'block' }}
        >
          {showIncludedVAT 
            ? (inputField.included !== null ? addSpaces(inputField.included) : '-')
            : (inputField.excluded !== null ? addSpaces(inputField.excluded) : '-')
          }
        </text>
      )}
    </div>
  );
}

export default IncomeCalendarCell;