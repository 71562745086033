import dayjs from 'dayjs';
import { call, put, takeEvery } from 'redux-saga/effects';
import { setTotalIncomeAmount } from '../../Redux/totalIncomeCardsReducer/action';
import { ActionTypes } from './actionsTypes';
import axiosInstance from '../../Axios/axiosInstance';

export function* watcherGetTotalIncomeAmount() {
  yield takeEvery(
    ActionTypes.GET_TOTAL_INCOME_AMOUNT,
    workerGetTotalIncomeAmount,
  );
}

export function* workerGetTotalIncomeAmount({ payload }) {
  try {
    console.log('Fetching income amount with payload:', {
      columns: payload.columns,
      filters: payload.filters,
      tableUID: payload.tableUID,
      timestamp: new Date().toISOString()
    });

    const body = {
      dates: payload.columns,
      ...(payload.filters?.category ? { categories: [payload.filters.category] } : {})
    };

    console.log('Making API request with body:', JSON.stringify(body, null, 2));

    const res = yield call(
      axiosInstance.post,
      `/incomes/amounts`,
      body,
    );

    console.log('Received API response:', {
      success: !!res,
      dataLength: res?.length,
      data: JSON.stringify(res, null, 2),
      timestamp: new Date().toISOString()
    });
    
    if (!res || !Array.isArray(res)) {
      console.error('Invalid response from /incomes/amounts:', {
        response: JSON.stringify(res, null, 2),
        timestamp: new Date().toISOString()
      });
      return;
    }

    // Parse the response to ensure categories are preserved
    const parsedRes = res.map(mall => {
      if (!mall || typeof mall !== 'object') {
        console.error('Invalid mall data in response:', {
          mall: JSON.stringify(mall, null, 2),
          timestamp: new Date().toISOString()
        });
        return null;
      }

      return {
        ...mall,
        stores: Array.isArray(mall.stores) ? mall.stores.map(store => {
          if (!store || typeof store !== 'object') {
            console.error('Invalid store data in response:', {
              store: JSON.stringify(store, null, 2),
              timestamp: new Date().toISOString()
            });
            return null;
          }

          // Ensure category is a string
          const category = typeof store.category === 'string' ? store.category : 'Other retail';
          
          return {
            ...store,
            category
          };
        }).filter(Boolean) : []
      };
    }).filter(Boolean);
    
    console.log('Processed response:', {
      mallCount: parsedRes.length,
      storeCount: parsedRes.reduce((sum, mall) => sum + mall.stores.length, 0),
      data: JSON.stringify(parsedRes, null, 2),
      timestamp: new Date().toISOString()
    });

    if (parsedRes.length > 0) {
      console.log('Dispatching setTotalIncomeAmount with:', {
        tableUID: payload.tableUID,
        mallCount: parsedRes.length,
        data: JSON.stringify(parsedRes, null, 2),
        timestamp: new Date().toISOString()
      });

      yield put(setTotalIncomeAmount({ res: parsedRes, tableUID: payload.tableUID }));
    } else {
      console.error('No valid data after parsing response');
    }
  } catch (error) {
    console.error('Error in getTotalIncomeAmount saga:', {
      error,
      errorMessage: error?.message,
      errorResponse: error?.response?.data,
      timestamp: new Date().toISOString()
    });
  }
}

export function* watcherExportTotalIncomeAmount() {
  yield takeEvery(
    ActionTypes.EXPORT_TOTAL_INCOME_AMOUNT,
    workerExportTotalIncomeAmount,
  );
}

export function* workerExportTotalIncomeAmount({ payload }) {
  const currentYear = dayjs().get('year');
  const previousYear = dayjs().add(-1, 'year').get('year');
  const body = {
    dates: payload.addedColumn,
  };
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };
  axiosInstance({
    url: `/incomes/amounts/exports?${
      payload?.mallId ? `mallIds=${payload?.mallId}` : ''
    }${
      payload?.storeId ? `&storeIds=${payload?.storeId}` : ''
    }${payload?.tax ? `&tax=${payload?.tax.toLowerCase()}` : ''}${
      payload?.category ? `&category=${payload?.category}` : ''
    }&includeCurrentYear=${
      payload.checked[`${currentYear} (kr)`]
    }&includePreviousYear=${
      payload.checked[`${previousYear} (kr)`]
    }&includeMoneyDifference=${
      payload.checked['Diff (kr)']
    }&includePercentDifference=${payload.checked['Diff (%)']}&includeGroups=true`,
    method: 'POST',
    headers,
    data: body,
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${payload.name}.xlsx`);
    document.body.appendChild(link);
    link.click();
  });
}
