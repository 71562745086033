import { ActionTypes } from '../../Saga/storeIncome/actionsTypes';

const {
  SET_STORE_INCOME,
  SET_YEAR_TO_DATE_INCOME,
  SET_PREVIOUS_YEAR_INCOME,
  SET_ERROR
} = ActionTypes;

const initialState = {
  storeIncome: {
    data: [],
    page: 1,
    total: 0,
  },
  yearToDateIncome: [],
  previousYearIncome: [],
  loading: false,
  error: null
};

const storeIncomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STORE_INCOME:
      console.log('Setting store income:', {
        data: action.payload,
        timestamp: new Date().toISOString()
      });
      return {
        ...state,
        storeIncome: {
          data: Array.isArray(action.payload) ? action.payload : [],
          page: 1,
          total: Array.isArray(action.payload) ? action.payload.length : 0
        },
        loading: false,
        error: null
      };

    case SET_YEAR_TO_DATE_INCOME:
      console.log('Setting year to date income:', {
        data: action.payload,
        timestamp: new Date().toISOString()
      });
      return {
        ...state,
        yearToDateIncome: Array.isArray(action.payload) ? action.payload : [],
        loading: false,
        error: null
      };

    case SET_PREVIOUS_YEAR_INCOME:
      console.log('Setting previous year income:', {
        data: action.payload,
        timestamp: new Date().toISOString()
      });
      return {
        ...state,
        previousYearIncome: Array.isArray(action.payload) ? action.payload : [],
        loading: false,
        error: null
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    default:
      return state;
  }
};

export { storeIncomeReducer };
