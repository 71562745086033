import React from 'react';
import { useTranslation } from 'react-i18next';
import { getShouldDisplayDiff } from '../../../../../utils/getDiff';
import { sortColumnsByDate } from '../../../../../utils/columnUtils';
import { formatDateRange } from '../../../../../utils/formatDateRange';
import MinusCircle from '../../../../../../../assets/icons/MinusCircle';
import Add from '../../../../../../../assets/icons/Add';
import ToggleableEye from '../../../../ToggleableEye';
import VatMismatchHelper from '../VatMismatchHelper';
import './styles.scss';

const ColumnDisplay = ({
  column,
  onDateClick,
  onTypeClick,
  onTaxClick,
  isSelected,
  activeTab
}) => {
  const { t } = useTranslation();

  if (column.isDiff) {
    return (
      <div className="column-display" data-type="diff">
        <div className="column-display__diff">
          <span>{column.diffType === 'numbers' ? t('Diff') : t('Diff %')}</span>
        </div>
      </div>
    );
  }

  const isDateTab = activeTab === 'picker' || activeTab === 'presets';

  return (
    <div className={`column-display ${isSelected ? '--selected' : ''}`}>
      <button 
        className={`column-display__button ${isSelected && isDateTab ? '--active' : ''}`}
        onClick={() => onDateClick(column)}
      >
        {formatDateRange(column.from, column.to, t)}
      </button>
      <button 
        className={`column-display__button ${isSelected && activeTab === 'types' ? '--active' : ''}`}
        onClick={() => onTypeClick(column)}
      >
        {column.type ? t(column.type) : t('income')}
      </button>
      {column.type !== 'averageTransaction' && (
        <button 
          className={`column-display__button ${isSelected && activeTab === 'tax' ? '--active' : ''}`}
          onClick={() => onTaxClick(column)}
        >
          {column.tax === 'included' ? t('vat_display_included') : t('vat_display_excluded')}
        </button>
      )}
    </div>
  );
};

const ColumnsOverview = ({
  columns,
  selectedColumnId,
  onDateClick,
  onTypeClick,
  onTaxClick,
  onRemoveColumn,
  onToggleVisibility,
  onAddColumn,
  shouldShowAdd,
  activeTab,
  onAlignVat
}) => {
  const { t } = useTranslation();

  const handleToggleVisibility = (column) => {
    onToggleVisibility(column);
  };

  // Keep shouldShowDiff check for enabling/disabling diff columns
  const shouldShowDiff = getShouldDisplayDiff(columns);

  // Show all columns in overview, but mark diff columns as hidden when shouldShowDiff is false
  const sortedColumns = sortColumnsByDate(columns);

  return (
    <div className="columns-overview">
      <VatMismatchHelper columns={columns} onAlignVat={onAlignVat} />
      <div className="columns-overview__row">
        {sortedColumns.map(column => {
          // For diff columns, they should appear hidden if either:
          // - They are explicitly set as not visible
          // - shouldShowDiff is false (can't show diffs)
          const isHidden = column.isDiff ? (!column.isVisible || !shouldShowDiff) : !column.isVisible;
          
          return (
            <div 
              key={column.columnUID}
              className={`columns-overview__item ${isHidden ? '--hidden' : ''}`}
            >
              <div className="columns-overview__item-action">
                {column.isDiff ? (
                  <div 
                    className="toggle-button-wrapper" 
                    title={column.isVisible ? t('Hide diff column') : t('Show diff column')}
                  >
                    <ToggleableEye 
                      isDisabled={!shouldShowDiff || !column.isVisible}
                      onClick={() => handleToggleVisibility(column)}
                    />
                  </div>
                ) : (
                  <button
                    type="button"
                    className="remove-button"
                    onClick={() => onRemoveColumn(column)}
                    title={t('Delete column')}
                  >
                    <MinusCircle />
                  </button>
                )}
              </div>
              <ColumnDisplay
                column={column}
                onDateClick={onDateClick}
                onTypeClick={onTypeClick}
                onTaxClick={onTaxClick}
                isSelected={selectedColumnId === column.columnUID}
                activeTab={activeTab}
              />
            </div>
          );
        })}
        {shouldShowAdd && (
          <div className="columns-overview__item">
            <div className="columns-overview__item-action" />
            <button
              type="button"
              className="column-display add-column-display"
              onClick={onAddColumn}
              data-tooltip={t('Add new column')}
            >
              <Add />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ColumnsOverview;