import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import './style.scss';

const formatDate = (date) => {
  return date ? dayjs(date).format('DD.MM.YYYY') : '';
};

const StoreList = ({ stores = [], onStoreClick, renderStoreStatus, onSort, sortOrder }) => {
  const { t } = useTranslation();

  // Separate active and inactive stores
  const activeStores = stores.filter(store => store.isActive);
  const inactiveStores = stores.filter(store => !store.isActive);

  // Sort active stores by reporting status
  const sortedActiveStores = activeStores.sort((a, b) => {
    if (a.isReported === b.isReported) return 0;
    if (sortOrder === 'REPORTED') {
      return a.isReported ? -1 : 1;
    } else {
      return a.isReported ? 1 : -1;
    }
  });

  return (
    <div className="pc-stores-grid">
      <div className="pc-stores-list">
        <div className="pc-stores-list-header">
          <span>{t('')}</span>
          <span className="sortable" onClick={() => onSort()}>
            {t('status')}
          </span>
          <span>{t('start_date')}</span>
          <span>{t('end_date')}</span>
        </div>
        
        {/* Active stores */}
        {sortedActiveStores.map((store) => (
          <div
            key={store.id}
            className={`pc-store-option ${store.isReported ? 'reported' : 'not-reported'}`}
            onClick={() => onStoreClick(store)}
          >
            <span className="pc-store-option-name">{store.name}</span>
            {renderStoreStatus(store)}
            <span className="pc-store-option-value">{formatDate(store.startDate)}</span>
            <span className="pc-store-option-value">{formatDate(store.endDate)}</span>
          </div>
        ))}

        {/* Separator and inactive stores */}
        {inactiveStores.length > 0 && (
          <>
            <div className="pc-stores-list-separator" />
            {inactiveStores.map((store) => (
              <div
                key={store.id}
                className="pc-store-option inactive"
                onClick={() => onStoreClick(store)}
              >
                <span className="pc-store-option-name">{store.name}</span>
                <span className="pc-store-option-value">{t('not_active')}</span>
                <span className="pc-store-option-value">{formatDate(store.startDate)}</span>
                <span className="pc-store-option-value">{formatDate(store.endDate)}</span>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

const StoresGrid = React.memo(({ 
  stores = [], 
  onStoreClick, 
  loadMore, 
  isLoading, 
  currentDate,
  filters,
  onSortChange
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    console.log('StoresGrid - Received new stores:', {
      count: stores.length,
      firstStore: stores[0],
      filters
    });
  }, [stores, filters]);

  const renderStoreStatus = useCallback((store) => (
    <span className="pc-store-option-value">
      {store.isReported ? t('reported') : t('not_reported')}
    </span>
  ), [t]);

  const handleSort = useCallback(() => {
    const newOrder = filters.order === 'REPORTED' ? 'NOT_REPORTED' : 'REPORTED';
    console.log('StoresGrid - Changing sort order:', {
      currentOrder: filters.order,
      newOrder
    });
    onSortChange(newOrder);
  }, [filters.order, onSortChange]);

  return (
    <div className="pc-stores-grid-container">
      {isLoading ? (
        <div className="pc-stores-loading">{t('loading')}</div>
      ) : stores.length === 0 ? (
        <div className="pc-no-stores">{t('no_stores_found')}</div>
      ) : (
        <StoreList 
          stores={stores} 
          onStoreClick={onStoreClick} 
          renderStoreStatus={renderStoreStatus}
          onSort={handleSort}
          sortOrder={filters.order}
        />
      )}
    </div>
  );
});

StoresGrid.propTypes = {
  stores: PropTypes.array,
  onStoreClick: PropTypes.func.isRequired,
  loadMore: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  currentDate: PropTypes.object.isRequired, // dayjs object
  filters: PropTypes.object.isRequired,
  onSortChange: PropTypes.func.isRequired,
};

export default StoresGrid;
