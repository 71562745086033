import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getStoreIcon } from '../../../../../utils/storeIcons';
import './styles.scss';

const StoreList = ({ 
  malls, 
  selectedMallId, 
  tableUID, 
  tableSettings, 
  toggleStoreVisible, 
  allStores,
  searchQuery = '' 
}) => {
  const { t } = useTranslation();

  const filteredStores = useMemo(() => {
    const stores = selectedMallId 
      ? malls.find(mall => mall.id === selectedMallId)?.stores || []
      : allStores || [];

    let filteredStores = stores;
    const filterCategory = tableSettings.filters?.category;

    if (filterCategory) {
      filteredStores = stores.filter(store => store.category === filterCategory);
    }

    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filteredStores = filteredStores.filter(store => 
        store.name?.toString().toLowerCase().includes(query)
      );
    }

    return filteredStores.sort((a, b) => {
      const categoryA = a.category || 'Other retail';
      const categoryB = b.category || 'Other retail';
      const isHiddenA = tableSettings.hiddenStores?.includes(a.id);
      const isHiddenB = tableSettings.hiddenStores?.includes(b.id);
      
      // If one is hidden and the other isn't, put the hidden one last
      if (isHiddenA !== isHiddenB) {
        return isHiddenA ? 1 : -1;
      }
      
      // If they're in different categories
      if (categoryA !== categoryB) {
        return categoryA.localeCompare(categoryB);
      }
      
      // If they're in the same category, sort by name
      const nameA = String(a.name || '');
      const nameB = String(b.name || '');
      return nameA.localeCompare(nameB);
    });
  }, [malls, selectedMallId, allStores, searchQuery, tableSettings.filters?.category, tableSettings.hiddenStores]);

  return (
    <div className="retail-editor__stores">
      <div className="retail-editor__stores-grid">
        {filteredStores.length > 0 ? (
          filteredStores.map(store => {
            const isHidden = tableSettings.hiddenStores?.includes(store.id);
            const StoreIcon = getStoreIcon(store.category);
            return (
              <div 
                key={store.id}
                className={`retail-editor__store ${isHidden ? 'retail-editor__store--inactive' : ''}`}
                onClick={() => toggleStoreVisible(tableUID, store.id)}
              >
                <div className={`status-circle ${!isHidden ? 'active' : ''}`} />
                <div className="store-name">
                  {StoreIcon && <StoreIcon className="store-category-icon" />}
                  <div className="store-details">
                    <span>{store.name || ''}</span>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="retail-editor__no-stores">
            {tableSettings.filters?.category ? (
              <p>{t('No stores in this category')}</p>
            ) : (
              <p>{t('No stores found')}</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StoreList; 