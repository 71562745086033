import { call, put, takeEvery } from 'redux-saga/effects';
import { MAIN_URL } from '../../../utils/constants';
import {
  setAllMalls,
  setAllMallsUsers,
  setAllStores,
} from '../../Redux/filtersReducer/action';
import { ActionTypes } from './actionsTypes';
import axiosInstance from '../../Axios/axiosInstance';

export function* watcherGetAllMalls() {
  yield takeEvery(ActionTypes.GET_ALL_MALLS, workerGetAllMalls);
}

export function* workerGetAllMalls({ payload }) {
  try {
    console.log('Fetching malls with payload:', payload);
    const res = yield call(
      axiosInstance.get,
      `/malls?page=1&limit=100${
        payload?.order ? `&order=${payload?.order}` : ''
      }`,
    );
    console.log('Malls API response:', res);
    
    // The backend returns { data: [...malls] }
    yield put(setAllMalls(res.data));
  } catch (error) {
    console.error('Error fetching malls:', {
      error,
      status: error.response?.status,
      data: error.response?.data,
      config: error.config
    });
    yield put(setAllMalls([]));
  }
}

export function* watcherGetAllMallsUsers() {
  yield takeEvery(ActionTypes.GET_ALL_MALLS_USERS, workerGetAllMallsUsers);
}

export function* workerGetAllMallsUsers() {
  const res = yield call(axiosInstance.get, `/malls/?page=1&limit=100`);
  yield put(setAllMallsUsers(res));
}

export function* watcherGetAllStores() {
  yield takeEvery(ActionTypes.GET_ALL_STORES, workerGetAllStores);
}

export function* workerGetAllStores({ payload }) {
  const res = yield call(
    axiosInstance.get,
    `/stores/malls/${payload?.mallId}?page=1&limit=100${
      payload?.storeId ? `&storeId=${payload?.storeId}` : ''
    }${payload?.tax ? `&tax=${payload?.tax}` : ''}${
      payload?.order ? `&order=${payload?.order}` : ''
    }`,
  );
  yield put(setAllStores(res.data));
}
