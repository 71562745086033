import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const VatMismatchHelper = ({ columns, onAlignVat }) => {
  const { t } = useTranslation();

  // Check if there are any non-diff columns with different VAT settings
  const hasVatMismatch = () => {
    const nonDiffColumns = columns.filter(col => !col.isDiff);
    if (nonDiffColumns.length <= 1) return false;

    const firstVat = nonDiffColumns[0].tax;
    return nonDiffColumns.some(col => col.tax !== firstVat);
  };

  if (!hasVatMismatch()) return null;

  return (
    <div className="vat-mismatch">
      <div className="vat-mismatch__content">
        <span className="vat-mismatch__text">{t('vat_mismatch_warning')}</span>
        <button
          type="button"
          className="vat-mismatch__button"
          onClick={onAlignVat}
        >
          {t('align_vat')}
        </button>
      </div>
    </div>
  );
};

export default VatMismatchHelper; 