import { ActionTypes } from './actionsTypes';

export const getMalls = (payload) => ({
  type: ActionTypes.GET_MALLS,
  payload,
});
export const getStores = (payload) => ({
  type: ActionTypes.GET_STORES,
  payload,
});
export const getStoresNewUser = (payload) => ({
  type: ActionTypes.GET_STORES_NEW_USER,
  payload,
});
export const getGroups = () => ({
  type: ActionTypes.GET_STORE_GROUPS,
});
