import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { login } from '../../services/Saga/loginSaga/actions';
import { Colors } from '../../utils/colors';
import { APP_VERSION } from '../../utils/version';
import './style.scss';
import { Button } from '@mui/material';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { SessionExpiredAlert } from '../../components/SessionExpiredAlert';
import FontLogoO from '../../assets/logos/FontLogoO';

export const Login = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isVisiblePass, setIsVisiblePass] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const onPressLogin = () => {
    dispatch(login({ email, password, navigate }));
  };

  useEffect(() => {
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
  }, []);

  return (
    <div 
      className='mainWrapper' 
      style={{ 
        backgroundColor: Colors.mainBlack // This will show while the image loads
      }}
    >
      <div className='wrapper'>
        <SessionExpiredAlert />
        <div className='logo-wrapper'>
          <FontLogoO width={200} height={16} />
        </div>
        <div className='loginFormContainer'>
          <div
            style={{
              backgroundColor: Colors.mainBlack,
            }}
            className='inputsContainer'
          >
            <div className='inputEmailContainer'>
              <span
                style={{
                  color: Colors.white,
                }}
                className='inputTitle'
              >
                {t('email')}
              </span>
              <input
                type='email'
                style={{
                  backgroundColor: Colors.mainBlack,
                }}
                placeholder={t('email')}
                className='inputEmail'
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className='inputPasswordContainer'>
              <span
                style={{
                  color: Colors.white,
                }}
                className='inputTitle'
              >
                {t('password')}
              </span>
              <input
                className='inputPassword'
                type={isVisiblePass ? 'text' : 'password'}
                style={{
                  backgroundColor: Colors.mainBlack,
                }}
                placeholder={t('password')}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div
                onClick={() => setIsVisiblePass(!isVisiblePass)}
                className='eyeIconContainerLogin'
              >
                {isVisiblePass ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              </div>
            </div>
            <Button
              style={{
                color: Colors.white,
              }}
              className='buttonLogin'
              onClick={onPressLogin}
            >
              {t('log_in')}
            </Button>
          </div>
        </div>
        <div className='version-info'>
          {t('version')}: {APP_VERSION}
        </div>
      </div>
    </div>
  );
};
